// Sumber:
// https://github.com/tesseract-ocr/langdata_lstm/blob/main/sun/sun.wordlist
// https://raw.githubusercontent.com/perlancar/perl-WordList-SU-LTIS/master/lib/WordList/SU/LTIS.pm
// http://kamus-sunda.com

export const VALID_GUESSES = [
  'antep',
  'jalma',
  'jalmi',
  'soleh',
  'abjad',
  'absen',
  'dawuk',
  'hawuk',
  'endah',
  'pisan',
  'iklan',
  'agama',
  'sipat',
  'agung',
  'ajaib',
  'siksa',
  'sarua',
  'uteuk',
  'ahlak',
  'eyang',
  'dalit',
  'aktip',
  'getol',
  'eurih',
  'kocor',
  'halis',
  'perak',
  'allah',
  'tiasa',
  'cokot',
  'putra',
  'ambek',
  'maneh',
  'aheng',
  'angin',
  'nitah',
  'jajap',
  'antay',
  'anyam',
  'lamun',
  'april',
  'apung',
  'arena',
  'arsip',
  'harti',
  'sarap',
  'pinuh',
  'kawit',
  'asasi',
  'sejen',
  'asing',
  'mengi',
  'aspal',
  'aspek',
  'uplek',
  'atlet',
  'atawa',
  'hayam',
  'soang',
  'lemar',
  'bisul',
  'babak',
  'kadek',
  'ngaos',
  'badan',
  'bagea',
  'bahan',
  'pedar',
  'bahya',
  'bakar',
  'bakat',
  'balap',
  'bales',
  'mulih',
  'balik',
  'balur',
  'liwar',
  'gugah',
  'kulem',
  'bantu',
  'sugan',
  'kulon',
  'baret',
  'anyar',
  'nembe',
  'karek',
  'basis',
  'basmi',
  'wasuh',
  'batil',
  'semet',
  'batik',
  'batuk',
  'ambeu',
  'bayem',
  'bayar',
  'mayar',
  'beber',
  'entog',
  'bebas',
  'becek',
  'geseh',
  'pupur',
  'wedak',
  'bedug',
  'tabuh',
  'pukul',
  'bejad',
  'bekel',
  'tilas',
  'palih',
  'engke',
  'meser',
  'meuli',
  'tacan',
  'belut',
  'leres',
  'bener',
  'binih',
  'acara',
  'jeung',
  'harga',
  'hargi',
  'caian',
  'bakti',
  'mayun',
  'malik',
  'beuli',
  'wagel',
  'carek',
  'nyora',
  'pirak',
  'pegat',
  'bogoh',
  'getih',
  'taman',
  'dehem',
  'dekem',
  'hieng',
  'deang',
  'jajar',
  'duaan',
  'genep',
  'tujuh',
  'ganti',
  'gelar',
  'noyek',
  'muket',
  'hajat',
  'gelut',
  'junun',
  'hasil',
  'hemat',
  'liren',
  'pasih',
  'katut',
  'kabar',
  'siaga',
  'jagal',
  'mapan',
  'mapah',
  'juang',
  'ngaca',
  'silih',
  'kedok',
  'beres',
  'hibar',
  'kumis',
  'diadu',
  'wargi',
  'liwat',
  'nerus',
  'neras',
  'letah',
  'heula',
  'liang',
  'ameng',
  'palay',
  'lucir',
  'dumuk',
  'napsu',
  'pamit',
  'tempo',
  'misah',
  'pesen',
  'mikir',
  'puasa',
  'salah',
  'babar',
  'purun',
  'nyesa',
  'skala',
  'naros',
  'pasea',
  'tugas',
  'dines',
  'robah',
  'robih',
  'buruy',
  'bared',
  'baeud',
  'warna',
  'watek',
  'besan',
  'badag',
  'beusi',
  'bitis',
  'nyaan',
  'dawam',
  'biasa',
  'biwir',
  'bijak',
  'wijen',
  'mijen',
  'damel',
  'bilik',
  'bulao',
  'pireu',
  'bendo',
  'udeng',
  'bobol',
  'dobol',
  'momot',
  'budak',
  'bocor',
  'belet',
  'waduk',
  'bolos',
  'boros',
  'hatur',
  'jieun',
  'buaya',
  'bubar',
  'bubur',
  'torek',
  'bogel',
  'bobor',
  'sanes',
  'pasir',
  'bukti',
  'sasih',
  'bulan',
  'patos',
  'buntu',
  'buruh',
  'manuk',
  'butek',
  'perlu',
  'butuh',
  'puyar',
  'rubuh',
  'dulur',
  'dahan',
  'betot',
  'kuris',
  'cacad',
  'cegeg',
  'cahya',
  'cagak',
  'cakar',
  'candu',
  'serat',
  'cawet',
  'catet',
  'isang',
  'caram',
  'rikat',
  'cegat',
  'cekek',
  'dadak',
  'bodor',
  'darat',
  'batan',
  'dasar',
  'dedek',
  'dekil',
  'caket',
  'dangu',
  'denge',
  'julig',
  'derek',
  'depot',
  'payun',
  'nyeri',
  'sedek',
  'rinci',
  'detak',
  'detik',
  'dewan',
  'acung',
  'wawuh',
  'aping',
  'bahas',
  'dagor',
  'pecut',
  'cabut',
  'teang',
  'cicil',
  'cipta',
  'copet',
  'conto',
  'culik',
  'desek',
  'didik',
  'poyok',
  'jawil',
  'gebug',
  'gegel',
  'geser',
  'gilir',
  'godog',
  'gosok',
  'gugat',
  'hadir',
  'palid',
  'hirup',
  'palar',
  'suguh',
  'etang',
  'itung',
  'hujan',
  'hukum',
  'inget',
  'incer',
  'intip',
  'lebet',
  'kaput',
  'jamin',
  'jalan',
  'letak',
  'kabul',
  'kawal',
  'sebut',
  'omong',
  'kawin',
  'kenal',
  'nyaho',
  'pupuh',
  'kirim',
  'konci',
  'korek',
  'dikte',
  'kubur',
  'pesek',
  'bedah',
  'tuluy',
  'lapis',
  'alung',
  'ciduh',
  'wayuh',
  'mahal',
  'tuang',
  'dahar',
  'makam',
  'antos',
  'tiris',
  'pacul',
  'pager',
  'panas',
  'pasti',
  'cekel',
  'piara',
  'petik',
  'tampa',
  'takol',
  'gaduh',
  'puter',
  'cabak',
  'racun',
  'rebut',
  'rawat',
  'gecek',
  'saksi',
  'cutat',
  'salur',
  'sepak',
  'sered',
  'tunda',
  'jabel',
  'titah',
  'surat',
  'taluk',
  'tagih',
  'sieun',
  'tanda',
  'taros',
  'tewak',
  'tarik',
  'tebak',
  'dedet',
  'teleg',
  'tawar',
  'susud',
  'batur',
  'lelep',
  'dites',
  'tulis',
  'turun',
  'turut',
  'bujur',
  'dukun',
  'susah',
  'dunya',
  'cucuk',
  'domba',
  'edisi',
  'emper',
  'encer',
  'etika',
  'palak',
  'fatwa',
  'pilem',
  'forum',
  'gagal',
  'gajah',
  'gajih',
  'kejem',
  'dobel',
  'katir',
  'kasep',
  'galak',
  'gurat',
  'garis',
  'digas',
  'ateul',
  'gayot',
  'gaber',
  'gelas',
  'getek',
  'guyur',
  'resep',
  'geret',
  'gesek',
  'kesit',
  'geter',
  'huntu',
  'gincu',
  'gitar',
  'bedog',
  'gogog',
  'turih',
  'gulat',
  'pelem',
  'saung',
  'hakim',
  'halal',
  'lemes',
  'mawur',
  'bobot',
  'gabuk',
  'ampir',
  'ancur',
  'anduk',
  'ajeng',
  'pupus',
  'poean',
  'maung',
  'harta',
  'banda',
  'kedah',
  'hebat',
  'manah',
  'helok',
  'heran',
  'hibur',
  'irung',
  'hilir',
  'honor',
  'hotel',
  'hurup',
  'jurig',
  'ideal',
  'injuk',
  'ihwal',
  'umbel',
  'emboh',
  'impor',
  'babon',
  'jalir',
  'eling',
  'noong',
  'iring',
  'injil',
  'islam',
  'istri',
  'garwa',
  'jadah',
  'jagat',
  'jadug',
  'jahat',
  'jajan',
  'jaksa',
  'jambu',
  'randa',
  'jaran',
  'jarum',
  'labuh',
  'tebih',
  'waler',
  'walon',
  'cawel',
  'tapak',
  'jejel',
  'nyata',
  'papag',
  'bosen',
  'layon',
  'mayit',
  'layad',
  'gemes',
  'jawer',
  'macem',
  'jiret',
  'jerit',
  'kapok',
  'jeruk',
  'trali',
  'jiwir',
  'jilid',
  'jorok',
  'jujur',
  'judul',
  'jurus',
  'keder',
  'kabel',
  'kabur',
  'kacow',
  'kader',
  'lawon',
  'useup',
  'akang',
  'akina',
  'kawon',
  'bilih',
  'kamar',
  'kemis',
  'kamus',
  'dusun',
  'kapal',
  'kapuk',
  'iraha',
  'kapur',
  'karat',
  'margi',
  'sabab',
  'raket',
  'karet',
  'karir',
  'kartu',
  'karya',
  'dugal',
  'kasar',
  'watir',
  'kasur',
  'kecap',
  'cenah',
  'pogot',
  'kebal',
  'kebon',
  'berik',
  'keker',
  'hilap',
  'kelas',
  'lalay',
  'ubeng',
  'lakop',
  'medal',
  'bijil',
  'lugay',
  'ledeh',
  'lecet',
  'keuna',
  'cikur',
  'bobos',
  'hitut',
  'wareg',
  'sesek',
  'sirah',
  'andel',
  'bodas',
  'angir',
  'betah',
  'teuas',
  'kerod',
  'keris',
  'kiruh',
  'tiung',
  'keduk',
  'kesan',
  'nyaah',
  'kelek',
  'waktu',
  'sabot',
  'kolot',
  'kupat',
  'husus',
  'kilat',
  'kipas',
  'kenca',
  'kitab',
  'urang',
  'kisut',
  'kobok',
  'kolek',
  'lajur',
  'kompa',
  'koran',
  'korps',
  'kotak',
  'kureb',
  'kucir',
  'kemot',
  'kulit',
  'jaram',
  'riung',
  'belok',
  'capek',
  'butik',
  'ceuli',
  'cepil',
  'korma',
  'kuras',
  'korsi',
  'salin',
  'busik',
  'budug',
  'kutuk',
  'jegud',
  'merek',
  'lahan',
  'rewog',
  'tenjo',
  'lakon',
  'lalab',
  'lalim',
  'dadah',
  'lampu',
  'lante',
  'teras',
  'lapor',
  'cegah',
  'laras',
  'baris',
  'sangu',
  'lawak',
  'lawan',
  'layak',
  'rubak',
  'layar',
  'layan',
  'laden',
  'lebar',
  'gasik',
  'legok',
  'pepek',
  'kusta',
  'terus',
  'licik',
  'nyere',
  'giles',
  'ganda',
  'leret',
  'liter',
  'logat',
  'logor',
  'ludah',
  'leuwi',
  'remuk',
  'lulus',
  'lumer',
  'lukut',
  'lunas',
  'impas',
  'mabok',
  'madya',
  'arang',
  'leeut',
  'beuki',
  'malih',
  'malah',
  'wengi',
  'kedul',
  'males',
  'siram',
  'nagen',
  'gabug',
  'jalmi',
  'bendu',
  'maret',
  'massa',
  'mahar',
  'masih',
  'panon',
  'nilar',
  'medis',
  'media',
  'mayor',
  'mekar',
  'megar',
  'madon',
  'neang',
  'kalah',
  'lamar',
  'layat',
  'leyur',
  'matak',
  'giwar',
  'lesot',
  'lipur',
  'ancul',
  'mager',
  'maksa',
  'manen',
  'emang',
  'marud',
  'masak',
  'matri',
  'paten',
  'macok',
  'pacok',
  'duruk',
  'ruhay',
  'wates',
  'belek',
  'mekel',
  'bekem',
  'miara',
  'metik',
  'mesen',
  'milih',
  'mojok',
  'menta',
  'mulas',
  'mucuk',
  'muter',
  'nagih',
  'nahan',
  'naker',
  'melak',
  'newak',
  'metot',
  'napak',
  'narik',
  'nawis',
  'ceker',
  'macul',
  'nyium',
  'neteg',
  'golak',
  'nebak',
  'neleg',
  'nelen',
  'mapay',
  'cabok',
  'ninun',
  'nolak',
  'terap',
  'narep',
  'nerap',
  'medar',
  'nampi',
  'usaha',
  'ngaji',
  'ngaku',
  'ulang',
  'mihak',
  'leber',
  'gupay',
  'kerek',
  'ngais',
  'gubah',
  'ngado',
  'magak',
  'ampar',
  'talar',
  'nalar',
  'mupus',
  'ngece',
  'ombak',
  'heuay',
  'angob',
  'ruang',
  'nulak',
  'mesek',
  'nikah',
  'lisah',
  'niron',
  'menit',
  'nyobi',
  'jawab',
  'mikat',
  'dedel',
  'mapag',
  'nulis',
  'mudun',
  'nurut',
  'nojos',
  'nutup',
  'murub',
  'nyisi',
  'nyana',
  'nyapu',
  'ngait',
  'jimak',
  'seger',
  'pikir',
  'nyewa',
  'nunda',
  'asong',
  'cocok',
  'nyusu',
  'pugur',
  'racik',
  'untay',
  'pedes',
  'mesin',
  'metro',
  'wadah',
  'waris',
  'kaduh',
  'milik',
  'gigir',
  'penta',
  'upami',
  'upama',
  'mobil',
  'mitra',
  'modal',
  'model',
  'modem',
  'moril',
  'motor',
  'ngora',
  'ampuh',
  'megah',
  'muluk',
  'baham',
  'wedal',
  'weton',
  'mirah',
  'murah',
  'mesum',
  'balai',
  'murid',
  'musik',
  'satru',
  'musuh',
  'cinta',
  'nadar',
  'wasta',
  'nanah',
  'nasib',
  'ganas',
  'ngaco',
  'kebut',
  'nomer',
  'nyiru',
  'eneng',
  'nagog',
  'colen',
  'pokna',
  'optik',
  'opini',
  'sepuh',
  'indit',
  'pacet',
  'padet',
  'pahat',
  'pajeg',
  'anggo',
  'parab',
  'paksa',
  'pamer',
  'panah',
  'panen',
  'tuduh',
  'birit',
  'papak',
  'papan',
  'parna',
  'serak',
  'parud',
  'bayah',
  'pasar',
  'belog',
  'pites',
  'patok',
  'tumut',
  'patut',
  'payah',
  'rampe',
  'pegel',
  'puade',
  'turis',
  'buron',
  'pelat',
  'gemuk',
  'pedit',
  'damar',
  'pelor',
  'watak',
  'biaya',
  'penca',
  'pisah',
  'tilik',
  'nilik',
  'mabek',
  'jidar',
  'katel',
  'ceret',
  'paria',
  'tetek',
  'lomba',
  'jempe',
  'demes',
  'jampe',
  'gelap',
  'pijer',
  'pihak',
  'pilih',
  'imbit',
  'kayas',
  'untun',
  'panto',
  'damis',
  'pokok',
  'pulas',
  'popor',
  'perih',
  'beger',
  'peang',
  'pucuk',
  'pucet',
  'sunda',
  'pules',
  'palsu',
  'puluh',
  'bujal',
  'berak',
  'pupuk',
  'pusat',
  'lieur',
  'rieut',
  'putri',
  'siswa',
  'lamur',
  'wanda',
  'rakit',
  'rajut',
  'ramal',
  'ribut',
  'rapia',
  'begal',
  'bajag',
  'rante',
  'rapuh',
  'ripuh',
  'rosul',
  'ratus',
  'ranca',
  'werit',
  'rebab',
  'kulub',
  'iklas',
  'remeh',
  'rames',
  'rangu',
  'repot',
  'resmi',
  'rikes',
  'murag',
  'ronda',
  'rugel',
  'rukun',
  'jukut',
  'pande',
  'rusak',
  'lebur',
  'ruwet',
  'rutin',
  'sabar',
  'sadar',
  'saptu',
  'sobat',
  'saham',
  'sains',
  'kusir',
  'sakti',
  'pesak',
  'lepat',
  'salak',
  'salat',
  'salam',
  'samar',
  'sopan',
  'sawah',
  'kawas',
  'dumeh',
  'sebel',
  'sebar',
  'sebat',
  'sedep',
  'sadia',
  'cawis',
  'sedot',
  'ember',
  'segel',
  'kabeh',
  'sapoe',
  'babad',
  'aling',
  'kidul',
  'hinis',
  'seseg',
  'ampeg',
  'rupek',
  'bedil',
  'senat',
  'seeng',
  'todel',
  'senen',
  'kesed',
  'keset',
  'sipuh',
  'keser',
  'misan',
  'peura',
  'serep',
  'serbu',
  'abad,',
  'abong',
  'ajrih',
  'akses',
  'alona',
  'ambeh',
  'ancik',
  'angar',
  'angka',
  'anjog',
  'awewe',
  'ayana',
  'bagja',
  'bakal',
  'balem',
  'baruk',
  'batok',
  'beuti',
  'bobok',
  'caang',
  'cacak',
  'calon',
  'cedit',
  'cikal',
  'citak',
  'cocog',
  'cokel',
  'colok',
  'cukup',
  'curuk',
  'demit',
  'diaku',
  'dicoo',
  'dinya',
  'dubur',
  'endog',
  'eptek',
  'eupan',
  'fisik',
  'garah',
  'gasna',
  'gawir',
  'gedeg',
  'hiber',
  'iteuk',
  'jaman',
  'jeger',
  'jejer',
  'jelas',
  'jenis',
  'kadua',
  'kagok',
  'kahot',
  'kaler',
  'kebek',
  'kedal',
  'kelir',
  'kemba',
  'kotok',
  'kutan',
  'lahir',
  'lapar',
  'lipet',
  'luhur',
  'maher',
  'mandi',
  'mebes',
  'meded',
  'meres',
  'mesem',
  'meter',
  'nakol',
  'nampa',
  'nanya',
  'nempo',
  'ngali',
  'nuduh',
  'nyawa',
  'paham',
  'paila',
  'parat',
  'pasca',
  'patri',
  'pawon',
  'pelak',
  'petet',
  'poena',
  'punah',
  'rajol',
  'ramat',
  'rapet',
  'rapih',
  'regep',
  'regot',
  'rukmi',
  'saban',
  'salse',
  'samak',
  'sapuk',
  'sarat',
  'sarta',
  'sarwa',
  'satia',
  'semah',
  'seuri',
  'sipil',
  'sirit',
  'sisit',
  'solat',
  'studi',
  'subuh',
  'sumur',
  'supir',
  'surup',
  'tahan',
  'taker',
  'takis',
  'tande',
  'tanpa',
  'tanya',
  'tapis',
  'tasma',
  'tegep',
  'teger',
  'teges',
  'teguh',
  'telat',
  'tepas',
  'tetep',
  'tinun',
  'titik',
  'tiwas',
  'total',
  'treuk',
  'tumbu',
  'turuy',
  'tutul',
  'tutup',
  'ucing',
  'ujung',
  'umroh',
  'unsur',
  'utama',
  'versi',
  'video',
  'wajar',
  'wajib',
  'waras',
  'warga',
  'warta',
  'wayah',
  'weruh',
  'wetan',
  'wiati',
  'wungu',
  'yakin',
  'yatim',
  'zabur',
  'zakat',
  'balad',
  'pedah',
  'lemah',
  'setor',
  'licin',
  'wilah',
  'rapat',
  'sihir',
  'cakra',
  'singa',
  'sebor',
  'sisir',
  'susuk',
  'sogok',
  'burit',
  'surak',
  'sesah',
  'suluh',
  'burem',
  'sisig',
  'susur',
  'sukur',
  'siang',
  'nuhun',
  'ngewa',
  'pipah',
  'tadah',
  'tawis',
  'tolak',
  'tarif',
  'ibing',
  'tatal',
  'tatar',
  'tatak',
  'tegas',
  'tekad',
  'parap',
  'telen',
  'tepos',
  'tepak',
  'tabah',
  'sedih',
  'impit',
  'tampi',
  'jajah',
  'kaget',
  'undur',
  'liwet',
  'kerem',
  'dupak',
  'karep',
  'sasar',
  'titip',
  'haben',
  'wujud',
  'inang',
  'kersa',
  'weleh',
  'wales',
  'tinju',
  'tobat',
  'topik',
  'dakwa',
  'liron',
  'tulus',
  'patuh',
  'kecit',
  'artos',
  'embun',
  'ujian',
  'yuswa',
  'utami',
  'valas',
  'wadal',
  'wakil',
  'wanci',
  'kakus',
  'abdas',
  'muhun',
  'yakti',
  'mamas',
  'babat',
  'banyo',
  'cacah',
  'curug',
  'embak',
  'belas',
  'manis',
  'legam',
  'ingon',
  'jambe',
  'mipis',
  'sulur',
  'kitri',
  'kusen',
  'lalar',
  'lihey',
  'ludes',
  'luduh',
  'sahid',
  'motah',
  'gitek',
  'perah',
  'receh',
  'rekep',
  'remen',
  'sepur',
  'gebog',
  'putus',
  'kebul',
  'kored',
  'koret',
  'patil',
  'matil',
  'sepet',
  'cepet',
  'gusti',
  'keneh',
  'barat',
  'alloh',
  'ahmad',
  'geura',
  'array',
  'puguh',
  'hidep',
  'bogor',
  'lapak',
  'raden',
  'mesir',
  'deuih',
  'prabu',
  'kaula',
  'sajak',
  'tanah',
  'kapan',
  'lebah',
  'najan',
  'paket',
  'kitoe',
  'kawih',
  'ujang',
  'frasa',
  'garut',
  'tokoh',
  'nagri',
  'sidik',
  'sakur',
  'cicih',
  'group',
  'drama',
  'sifat',
  'novel',
  'yusup',
  'raina',
  'ngala',
  'batin',
  'depok',
  'sikep',
  'rumah',
  'puisi',
  'habsi',
  'yahoo',
  'patih',
  'lisan',
  'moesa',
  'radja',
  'nyaur',
  'abdul',
  'galuh',
  'timur',
  'welas',
  'mamah',
  'kijeu',
  'nyiar',
  'catur',
  'sunan',
  'andir',
  'jurit',
  'ratna',
  'kanum',
  'mugia',
  'hidji',
  'dalil',
  'nafsu',
  'djadi',
  'dalem',
  'galur',
  'tahun',
  'galih',
  'wrote',
  'email',
  'calik',
  'dinja',
  'vokal',
  'place',
  'pasal',
  'maman',
  'dapur',
  'caina',
  'polah',
  'putih',
  'radio',
  'aktif',
  'daria',
  'taala',
  'latar',
  'oelah',
  'miang',
  'alias',
  'darso',
  'rayat',
  'soteh',
  'nimat',
  'matur',
  'jisim',
  'lirik',
  'ngadu',
  'mulya',
  'kanji',
  'names',
  'erwin',
  'kalbu',
  'orang',
  'ilang',
  'miwah',
  'sehat',
  'nyoko',
  'kocap',
  'edwar',
  'dinas',
  'akhir',
  'buhun',
  'adina',
  'hyang',
  'doewa',
  'memeh',
  'sujud',
  'sasat',
  'dohir',
  'gagah',
  'nenjo',
  'tahap',
  'sumbi',
  'lokal',
  'lurah',
  'mekah',
  'menak',
  'panji',
  'malar',
  'yesus',
  'tamat',
  'india',
  'hasan',
  'tibra',
  'untuk',
  'ulama',
  'toret',
  'sinta',
  'sirna',
  'genah',
  'cepot',
  'maham',
  'dewek',
  'matuh',
  'seler',
  'jimat',
  'hurip',
  'ragam',
  'insya',
  'sakit',
  'araya',
  'tidak',
  'jalur',
  'jabar',
  'ngilu',
  'ciung',
  'butut',
  'setan',
  'kebat',
  'wanoh',
  'kerep',
  'rusuh',
  'hamba',
  'bibit',
  'zaman',
  'ibuna',
  'situs',
  'utara',
  'jasad',
  'haram',
  'lawas',
  'yayan',
  'ketua',
  'rubil',
  'bedas',
  'undak',
  'misti',
  'rasul',
  'moral',
  'surti',
  'dalah',
  'indah',
  'links',
  'nyaba',
  'track',
  'datuk',
  'kunci',
  'surya',
  'djero',
  'upaya',
  'kalih',
  'yakub',
  'yahya',
  'tabel',
  'dadan',
  'tereh',
  'paman',
  'kotor',
  'semar',
  'prosa',
  'rawuh',
  'cekap',
  'tegal',
  'dunia',
  'altar',
  'temen',
  'majar',
  'bagus',
  'yusuf',
  'baduy',
  'mania',
  'inten',
  'mulus',
  'hewan',
  'sarah',
  'sukma',
  'karna',
  'tamba',
  'wahyu',
  'lafad',
  'gerak',
  'bapak',
  'mista',
  'usman',
  'rajin',
  'nyoba',
  'besar',
  'unduh',
  'meuni',
  'kerja',
  'buana',
  'peran',
  'juara',
  'eweuh',
  'gagak',
  'luang',
  'luput',
  'rajah',
  'saran',
  'sekar',
  'purah',
  'hitam',
  'napel',
  'fiksi',
  'fonem',
  'milis',
  'hindu',
  'raray',
  'badut',
  'makan',
  'janji',
  'unpad',
  'teteh',
  'sirik',
  'laksa',
  'mampu',
  'subur',
  'taoen',
  'bukid',
  'suhud',
  'dawuh',
  'marga',
  'fakta',
  'malem',
  'ampun',
  'sugih',
  'pesta',
  'tiloe',
  'makna',
  'manon',
  'angen',
  'medan',
  'ketuk',
  'unina',
  'keren',
  'idjah',
  'atina',
  'wafat',
  'johar',
  'tasik',
  'aceuk',
  'camat',
  'emban',
  'asana',
  'aisan',
  'gemet',
  'hayoh',
  'sohor',
  'balas',
  'joeda',
  'lebak',
  'boemi',
  'musna',
  'euceu',
  'sampe',
  'diala',
  'raneh',
  'didin',
  'music',
  'darma',
  'mitos',
  'uncal',
  'naats',
  'rehna',
  'iblis',
  'sinom',
  'madni',
  'kamil',
  'sajna',
  'yoona',
  'preet',
  'lagai',
  'moegi',
  'najis',
  'nilai',
  'ngopi',
  'nyeta',
  'merak',
  'mimin',
  'simon',
  'minat',
  'surga',
  'sabri',
  'njaho',
  'kesel',
  'moyan',
  'ajian',
  'eulis',
  'napas',
  'kasih',
  'enung',
  'macan',
  'mulai',
  'endit',
  'nafas',
  'insan',
  'belah',
  'panca',
  'wangi',
  'ketan',
  'akoer',
  'album',
  'embah',
  'kanca',
  'ridho',
  'tigin',
  'kadar',
  'buruk',
  'tilar',
  'dedeh',
  'hidup',
  'tajug',
  'cadas',
  'biang',
  'eropa',
  'goong',
  'lohor',
  'johor',
  'jinis',
  'anteb',
  'songs',
  'lebih',
  'fitri',
  'objek',
  'turta',
  'manik',
  'juhri',
  'botol',
  'leuit',
  'lempi',
  'acuan',
  'golek',
  'pipir',
  'abbas',
  'kasus',
  'serta',
  'wawan',
  'simpe',
  'harus',
  'amung',
  'nyatu',
  'saoel',
  'dinar',
  'indra',
  'atuda',
  'bubuk',
  'tahta',
  'kituh',
  'karno',
  'somah',
  'pajar',
  'label',
  'harun',
  'badak',
  'cakal',
  'sutra',
  'sunat',
  'jolna',
  'gimir',
  'lapur',
  'silat',
  'batal',
  'sabda',
  'gedag',
  'manan',
  'mendi',
  'lilis',
  'unpas',
  'teori',
  'sabat',
  'ieung',
  'asoep',
  'mudik',
  'artis',
  'cidra',
  'gugur',
  'adang',
  'naeun',
  'malam',
  'ayena',
  'rusdi',
  'hanca',
  'nyuuh',
  'kiara',
  'talak',
  'wuwuh',
  'komen',
  'takwa',
  'tiori',
  'marom',
  'lumur',
  'ebreh',
  'unjuk',
  'bagan',
  'aisia',
  'jejen',
  'sabah',
  'lunta',
  'tutur',
  'sibuk',
  'lieuk',
  'lilin',
  'tjara',
  'anwar',
  'admin',
  'sulap',
  'tutut',
  'sorot',
  'inung',
  'south',
  'kafir',
  'hakna',
  'babul',
  'mount',
  'mugya',
  'hamim',
  'liana',
  'jarak',
  'jarah',
  'nuyun',
  'sowan',
  'barna',
  'gunem',
  'wireh',
  'badis',
  'matok',
  'keras',
  'meida',
  'mirip',
  'akbar',
  'runut',
  'megat',
  'nguji',
  'purwa',
  'antar',
  'syekh',
  'dirja',
  'seban',
  'nelah',
  'nolih',
  'kalem',
  'payus',
  'misil',
  'datip',
  'geger',
  'kapas',
  'eutik',
  'wakca',
  'elias',
  'saged',
  'rusul',
  'ratug',
  'asian',
  'kauni',
  'irman',
  'ihlas',
  'jejeg',
  'suara',
  'kaera',
  'jirim',
  'miror',
  'yayah',
  'rumus',
  'udara',
  'ustad',
  'angon',
  'humor',
  'namin',
  'north',
  'mamat',
  'amang',
  'sapta',
  'malum',
  'perda',
  'telik',
  'darah',
  'pandu',
  'jubah',
  'uwang',
  'sudah',
  'tidur',
  'turki',
  'saena',
  'sadat',
  'tepat',
  'kemah',
  'madep',
  'sasak',
  'murni',
  'kalau',
  'gebeg',
  'prung',
  'oncom',
  'obyek',
  'gober',
  'jihat',
  'demak',
  'nepak',
  'muara',
  'sieup',
  'budal',
  'jebul',
  'lewih',
  'ciawi',
  'tisna',
  'mawar',
  'tilam',
  'logis',
  'puyuh',
  'dapat',
  'kukuh',
  'korea',
  'bahwa',
  'atton',
  'kufur',
  'smith',
  'ishak',
  'nurul',
  'tulak',
  'tutug',
  'sayah',
  'kalde',
  'gadis',
  'namah',
  'kunti',
  'mukti',
  'eunji',
  'pange',
  'riksa',
  'lilir',
  'ketak',
  'mijil',
  'dosen',
  'purba',
  'batoe',
  'sajam',
  'sayur',
  'angga',
  'abang',
  'apink',
  'mijah',
  'kuota',
  'arina',
  'romli',
  'jeput',
  'kapir',
  'bukan',
  'matih',
  'plang',
  'papua',
  'salim',
  'bambu',
  'jinah',
  'harep',
  'cempa',
  'bubat',
  'ketir',
  'harja',
  'kolom',
  'rupia',
  'repeh',
  'hadis',
  'wulan',
  'imeut',
  'modul',
  'rujak',
  'domas',
  'ligar',
  'kodir',
  'minus',
  'tohid',
  'abadi',
  'mabur',
  'titin',
  'lepas',
  'kurdi',
  'dahri',
  'candi',
  'jelma',
  'lacak',
  'perbu',
  'negri',
  'biluk',
  'butan',
  'leleb',
  'prebu',
  'rizki',
  'dijeu',
  'kamer',
  'turug',
  'cuang',
  'sasab',
  'bukit',
  'bakri',
  'nebus',
  'jadul',
  'bubut',
  'kawah',
  'kadal',
  'dawah',
  'roman',
  'karta',
  'kalam',
  'datar',
  'dampi',
  'latin',
  'bunga',
  'title',
  'jinek',
  'rijki',
  'sugri',
  'sikap',
  'sapun',
  'adzan',
  'komik',
  'polow',
  'bisma',
  'gilig',
  'bueuk',
  'wastu',
  'pulsa',
  'yudas',
  'lemek',
  'durma',
  'bubuy',
  'yudea',
  'reboe',
  'marek',
  'sayid',
  'buyut',
  'semua',
  'entep',
  'hakan',
  'nitih',
  'oceng',
  'hanas',
  'dedeg',
  'rinni',
  'cukur',
  'lewat',
  'modar',
  'jumat',
  'gurun',
  'pecat',
  'macet',
  'tilem',
  'kendi',
  'uding',
  'hanoi',
  'jihad',
  'laoet',
  'anjuk',
  'kujur',
  'mando',
  'pidik',
  'pintu',
  'kaset',
  'junti',
  'heman',
  'dorna',
  'citra',
  'frame',
  'nomor',
  'alami',
  'taran',
  'tiket',
  'paska',
  'somad',
  'banyu',
  'hatta',
  'karah',
  'tutas',
  'waluh',
  'baleg',
  'himah',
  'damas',
  'yahod',
  'deden',
  'china',
  'pesan',
  'wisnu',
  'wadya',
  'tabib',
  'taken',
  'yaitu',
  'nawas',
  'piala',
  'bayus',
  'depan',
  'tempe',
  'soara',
  'tuwuh',
  'chord',
  'djeun',
  'areuy',
  'hanya',
  'adobe',
  'saleh',
  'mamih',
  'susul',
  'titis',
  'budur',
  'maria',
  'lolos',
  'share',
  'ranah',
  'cecep',
  'isbat',
  'remix',
  'khusu',
  'terah',
  'terms',
  'sanak',
  'black',
  'balon',
  'diaos',
  'caleg',
  'salai',
  'karim',
  'murka',
  'parah',
  'unjak',
  'yayat',
  'papay',
  'jalak',
  'paris',
  'cedok',
  'anjir',
  'pamuk',
  'hihid',
  'hiris',
  'pasif',
  'ihsan',
  'cacap',
  'gatot',
  'audio',
  'koper',
  'laten',
  'tirta',
  'halon',
  'deung',
  'kulah',
  'saudi',
  'uwana',
  'raksa',
  'dodol',
  'beluk',
  'kalis',
  'dapon',
  'enteh',
  'awang',
  'dihin',
  'keyan',
  'atang',
  'wadul',
  'agami',
  'sandi',
  'hayat',
  'kanan',
  'nilas',
  'isola',
  'kuala',
  'tadim',
  'bokor',
  'bella',
  'kolor',
  'pieun',
  'tuhan',
  'minta',
  'duduh',
  'diana',
  'yuyum',
  'motif',
  'narah',
  'dadas',
  'susun',
  'mindo',
  'arwah',
  'setra',
  'kabah',
  'cilok',
  'antel',
  'tetap',
  'sawed',
  'style',
  'dukuh',
  'kisah',
  'kaala',
  'posts',
  'musim',
  'sawer',
  'kawat',
  'mantu',
  'punya',
  'minda',
  'ngemu',
  'level',
  'halim',
  'yanti',
  'sipir',
  'dibui',
  'reret',
  'busan',
  'kania',
  'borok',
  'serah',
  'final',
  'batak',
  'there',
  'encan',
  'bauna',
  'galau',
  'norma',
  'pidua',
  'damin',
  'pamor',
  'yaksa',
  'taeun',
  'banga',
  'benda',
  'liman',
  'drona',
  'biola',
  'sahna',
  'katon',
  'magic',
  'dunga',
  'jabur',
  'emosi',
  'tebeh',
  'nasal',
  'akrab',
  'niley',
  'sabtu',
  'kohar',
  'ripah',
  'arman',
  'amien',
  'rahim',
  'telah',
  'ngolo',
  'piker',
  'their',
  'comot',
  'swara',
  'organ',
  'teluh',
  'hajar',
  'empat',
  'warti',
  'kursi',
  'pajak',
  'tamil',
  'incah',
  'magar',
  'karsa',
  'nyari',
  'angot',
  'otong',
  'nuang',
  'tuman',
  'astri',
  'sabun',
  'murba',
  'midua',
  'diuji',
  'sahur',
  'weweg',
  'areng',
  'tulen',
  'mohal',
  'suatu',
  'teteg',
  'sigah',
  'sateh',
  'thats',
  'diksi',
  'debat',
  'kimia',
  'fokus',
  'wapat',
  'ilham',
  'neupi',
  'bukur',
  'aceng',
  'gaang',
  'irama',
  'karak',
  'pakir',
  'tagog',
  'duana',
  'taksi',
  'nitip',
  'netep',
  'loyog',
  'bahla',
  'moyok',
  'gejul',
  'estoe',
  'kecil',
  'ageng',
  'hawek',
  'mesat',
  'onnok',
  'mulud',
  'herdi',
  'doran',
  'mukul',
  'yaman',
  'paadu',
  'mahdi',
  'nitis',
  'zahra',
  'hojah',
  'katil',
  'parek',
  'amiin',
  'enden',
  'rusia',
  'etnis',
  'tomat',
  'order',
  'nuhns',
  'syara',
  'budha',
  'nanti',
  'pelet',
  'virus',
  'gemah',
  'dicky',
  'seren',
  'tatag',
  'cumah',
  'badot',
  'fakir',
  'duloh',
  'meong',
  'selat',
  'madeg',
  'sinar',
  'pakar',
  'luwak',
  'ajang',
  'logam',
  'sudut',
  'seeur',
  'congo',
  'rohna',
  'salib',
  'oding',
  'dawud',
  'mewah',
  'mande',
  'rabul',
  'cikan',
  'diaji',
  'mipit',
  'teleb',
  'lamak',
  'teuin',
  'mobok',
  'ambri',
  'njawa',
  'intan',
  'nyiuk',
  'daeng',
  'sajah',
  'bring',
  'amjad',
  'bunuh',
  'setia',
  'umpan',
  'aneka',
  'anger',
  'lepra',
  'oemat',
  'messi',
  'reana',
  'wasit',
  'tapel',
  'barun',
  'gempa',
  'sirep',
  'enduy',
  'wadia',
  'braga',
  'kango',
  'kusut',
  'halna',
  'anjar',
  'masuk',
  'timah',
  'libur',
  'mabuk',
  'miyos',
  'hamid',
  'laina',
  'mudah',
  'manar',
  'hijau',
  'teung',
  'polos',
  'kades',
  'wantu',
  'alang',
  'iatna',
  'hijab',
  'tebar',
  'ilahi',
  'stats',
  'abram',
  'upeti',
  'bedja',
  'angge',
  'pemda',
  'krama',
  'nyair',
  'marak',
  'belum',
  'jabal',
  'malak',
  'ragap',
  'nirca',
  'wiwin',
  'isoek',
  'tedak',
  'bunda',
  'hadas',
  'super',
  'budah',
  'poyan',
  'pizza',
  'marta',
  'mamur',
  'ghaib',
  'denok',
  'house',
  'pilar',
  'duduk',
  'verba',
  'ledis',
  'munel',
  'kosta',
  'laban',
  'kodeu',
  'marni',
  'bekas',
  'kewuk',
  'ngeun',
  'panga',
  'mirig',
  'pulih',
  'lembu',
  'sanca',
  'popon',
  'anwas',
  'ayang',
  'restu',
  'merah',
  'human',
  'bidah',
  'panan',
  'puput',
  'petot',
  'lihat',
  'ebith',
  'nilem',
  'aktor',
  'sunah',
  'mulia',
  'ladju',
  'bedul',
  'tuhur',
  'berat',
  'samet',
  'kenur',
  'werat',
  'mudal',
  'kosim',
  'murak',
  'leler',
  'mugen',
  'beton',
  'mutih',
  'suluk',
  'saija',
  'story',
  'deddy',
  'nurun',
  'sidon',
  'manco',
  'kabir',
  'mutus',
  'imaji',
  'nadah',
  'balok',
  'aulia',
  'mumin',
  'dipan',
  'rineh',
  'kerta',
  'pasen',
  'hamil',
  'bango',
  'rasta',
  'ngaso',
  'odong',
  'deleg',
  'maani',
  'bugis',
  'bulus',
  'iklim',
  'onjoy',
  'modol',
  'diais',
  'wegah',
  'husna',
  'papah',
  'filem',
  'narun',
  'kadon',
  'batas',
  'gilad',
  'miraj',
  'wajah',
  'hieum',
  'papih',
  'kiyai',
  'trade',
  'ngong',
  'rokok',
  'fajar',
  'yoshi',
  'basri',
  'halus',
  'rucah',
  'bacot',
  'yoyoh',
  'color',
  'today',
  'cihea',
  'jebet',
  'silas',
  'ragem',
  'bpbdk',
  'laesa',
  'bulak',
  'sulit',
  'saraf',
  'kpang',
  'makin',
  'komis',
  'pecak',
  'dinda',
  'yunus',
  'husen',
  'engab',
  'mowal',
  'selir',
  'kiyat',
  'njaah',
  'warni',
  'dance',
  'uleng',
  'segut',
  'endul',
  'marah',
  'sutar',
  'surem',
  'sebab',
  'shaum',
  'neguh',
  'pakem',
  'meren',
  'maweh',
  'idola',
  'kandi',
  'paran',
  'minal',
  'iseng',
  'mogok',
  'jemur',
  'gugup',
  'panta',
  'going',
  'serba',
  'bazit',
  'sigay',
  'dadar',
  'pelog',
  'polis',
  'pilot',
  'lubis',
  'ikrar',
  'nisah',
  'nujum',
  'nekad',
  'sinis',
  'warah',
  'entis',
  'hegar',
  'deres',
  'kayon',
  'jujun',
  'nawar',
  'jamal',
  'bolay',
  'ursin',
  'balan',
  'kukus',
  'burih',
  'betus',
  'temah',
  'jetun',
  'tirus',
  'jodoh',
  'nista',
  'kasab',
  'azhar',
  'dayat',
  'robot',
  'sunia',
  'jilan',
  'serab',
  'sabuk',
  'salon',
  'emana',
  'rujit',
  'weeks',
  'kemed',
  'sorga',
  'anang',
  'anisa',
  'would',
  'koeda',
  'breng',
  'lempa',
  'remis',
  'ngari',
  'bakul',
  'pardi',
  'linci',
  'likur',
  'ilaha',
  'maneb',
  'piken',
  'sahad',
  'sekel',
  'memed',
  'babah',
  'sawal',
  'image',
  'antea',
  'grand',
  'where',
  'umuna',
  'kokom',
  'giler',
  'tagen',
  'ketok',
  'sirop',
  'jamna',
  'reang',
  'santi',
  'shows',
  'deket',
  'tajur',
  'opang',
  'teman',
  'kleub',
  'mubus',
  'miris',
  'salju',
  'sabet',
  'reply',
  'ingin',
  'major',
  'sardu',
  'umpak',
  'naptu',
  'benar',
  'udung',
  'jatah',
  'mabal',
  'burak',
  'jamak',
  'pulau',
  'ponyo',
  'villa',
  'ketah',
  'dolim',
  'mulat',
  'teluk',
  'shiam',
  'bayan',
  'aeara',
  'hasud',
  'karwi',
  'saron',
  'mamar',
  'ruhak',
  'doger',
  'kuluk',
  'saree',
  'miloe',
  'makom',
  'rohim',
  'ruhur',
  'niyat',
  'tesis',
  'power',
  'narti',
  'jepun',
  'tubuh',
  'kupah',
  'macam',
  'ayaan',
  'sereg',
  'paksi',
  'amben',
  'green',
  'games',
  'geude',
  'wekel',
  'komar',
  'ratih',
  'mulek',
  'jegur',
  'nimba',
  'gebut',
  'tggal',
  'kupas',
  'baron',
  'tugur',
  'subro',
  'jambi',
  'hajam',
  'suung',
  'wudlu',
  'lapad',
  'wudhu',
  'timba',
  'belem',
  'djoko',
  'entos',
  'guyon',
  'sakoe',
  'ashar',
  'jogja',
  'esaoe',
  'wuruk',
  'tukuh',
  'genre',
  'dhipa',
  'parok',
  'saria',
  'capit',
  'hours',
  'lacur',
  'muhit',
  'angel',
  'kolam',
  'duren',
  'pulus',
  'pacar',
  'elisa',
  'jumad',
  'imbuh',
  'ereun',
  'entin',
  'sosok',
  'randu',
  'adean',
  'salya',
  'wandi',
  'kepoh',
  'antik',
  'hafiz',
  'dapet',
  'pisik',
  'djgro',
  'misal',
  'ajana',
  'leweh',
  'jaluk',
  'naked',
  'teddy',
  'nolol',
  'santa',
  'behna',
  'asaan',
  'rizky',
  'heydi',
  'rampa',
  'cilik',
  'metal',
  'irwan',
  'ogoan',
  'yosep',
  'andri',
  'paser',
  'manga',
  'itali',
  'nyepi',
  'sarip',
  'again',
  'jatuh',
  'suami',
  'mecut',
  'molor',
  'sebul',
  'pakan',
  'sabil',
  'netra',
  'soeka',
  'guyub',
  'cuman',
  'hutan',
  'arita',
  'losna',
  'matut',
  'press',
  'patna',
  'pisin',
  'gebot',
  'baros',
  'duwit',
  'dadap',
  'meles',
  'idiom',
  'kiyeu',
  'dugul',
  'tepis',
  'inbox',
  'kulup',
  'reuma',
  'koplo',
  'keran',
  'toong',
  'bebek',
  'lemak',
  'saint',
  'waleh',
  'wedel',
  'unduk',
  'ucang',
  'halah',
  'diolo',
  'biyur',
  'nahun',
  'ramah',
  'namun',
  'buang',
  'ambon',
  'anana',
  'ummat',
  'ajsam',
  'perum',
  'cuaca',
  'hikam',
  'oknum',
  'samsu',
  'pecah',
  'anica',
  'selop',
  'kardi',
  'jedur',
  'papat',
  'patah',
  'rahel',
  'harak',
  'titim',
  'tuyul',
  'golok',
  'wajit',
  'jamur',
  'ahung',
  'aduan',
  'sardi',
  'mukim',
  'bumbu',
  'damai',
  'nakis',
  'ganja',
  'yogya',
  'haula',
  'gadag',
  'resik',
  'gusuh',
  'saini',
  'toeng',
  'nateh',
  'dirun',
  'lalis',
  'encep',
  'mimpi',
  'arsik',
  'akuan',
  'leles',
  'koeta',
  'thank',
  'acina',
  'nicky',
  'mabra',
  'kahar',
  'karma',
  'regol',
  'cutak',
  'rikip',
  'nugar',
  'sinai',
  'susan',
  'tuwan',
  'jatoh',
  'rajeg',
  'petir',
  'napsi',
  'seksi',
  'siapa',
  'gurit',
  'think',
  'aosan',
  'jleng',
  'dekok',
  'onyet',
  'panci',
  'janur',
  'apply',
  'hente',
  'sukra',
  'seuat',
  'verna',
  'numpi',
  'james',
  'hawar',
  'roepa',
  'sendi',
  'iding',
  'sound',
  'demam',
  'tujul',
  'seneu',
  'joged',
  'lulun',
  'kukut',
  'darga',
  'pekan',
  'jakat',
  'laoek',
  'juhdi',
  'sugal',
  'gunta',
  'rudin',
  'ugana',
  'panti',
  'ngara',
  'entas',
  'porot',
  'parin',
  'minah',
  'majid',
  'gurih',
  'rajet',
  'ragot',
  'pergi',
  'gabut',
  'mikro',
  'sieum',
  'input',
  'beban',
  'kupur',
  'getas',
  'boleh',
  'lagam',
  'halik',
  'muruk',
  'rijal',
  'gegek',
  'encid',
  'ngada',
  'kulan',
  'tapos',
  'imron',
  'turus',
  'wadon',
  'gayus',
  'mesra',
  'siswi',
  'lafal',
  'baong',
  'ridlo',
  'hayan',
  'catat',
  'pugag',
  'modus',
  'cewek',
  'bijun',
  'along',
  'saoer',
  'jaket',
  'silah',
  'sanga',
  'koral',
  'cepat',
  'happy',
  'sawit',
  'metet',
  'ialah',
  'mumul',
  'lepus',
  'mulok',
  'raong',
  'tjeuk',
  'juned',
  'amsal',
  'oeton',
  'negla',
  'dekan',
  'becik',
  'karia',
  'titus',
  'mipir',
  'anjen',
  'janma',
  'robbi',
  'minum',
  'sikat',
  'dipoe',
  'risma',
  'rudet',
  'heheh',
  'lanca',
  'cokor',
  'birat',
  'nujul',
  'lasut',
  'denda',
  'citeh',
  'sabba',
  'award',
  'kanta',
  'numan',
  'djang',
  'tepus',
  'naktu',
  'kosan',
  'mssir',
  'buris',
  'bimbo',
  'jikan',
  'nguni',
  'hatam',
  'comro',
  'tweet',
  'event',
  'asoer',
  'nenek',
  'jomlo',
  'barau',
  'jamus',
  'numbu',
  'ngeus',
  'tular',
  'taaah',
  'rdpan',
  'pulen',
  'donat',
  'gosip',
  'katun',
  'sedan',
  'udang',
  'kumah',
  'ngilo',
  'leguk',
  'indri',
  'sltpn',
  'kamal',
  'rubah',
  'cakep',
  'nkeun',
  'based',
  'tikel',
  'march',
  'diogo',
  'rahul',
  'tuluj',
  'bidan',
  'tomas',
  'sisip',
  'sista',
  'lensa',
  'ajrak',
  'cueut',
  'salma',
  'kojor',
  'weduk',
  'kanda',
  'tongo',
  'entri',
  'kaina',
  'faham',
  'anggi',
  'duyeh',
  'mautu',
  'porno',
  'corak',
  'dayak',
  'indir',
  'ajali',
  'larap',
  'utari',
  'kocak',
  'deupa',
  'those',
  'surah',
  'boled',
  'petak',
  'pikun',
  'liket',
  'ngejo',
  'senin',
  'leuir',
  'djauh',
  'gugon',
  'talem',
  'cetna',
  'dikir',
  'bates',
  'sejak',
  'tateh',
  'endun',
  'judes',
  'junub',
  'baewe',
  'geten',
  'nanda',
  'habek',
  'promo',
  'sukri',
  'heart',
  'kamis',
  'kadut',
  'embok',
  'dikun',
  'iwung',
  'pogor',
  'yakni',
  'silpi',
  'kewes',
  'short',
  'majas',
  'flora',
  'kanal',
  'dulag',
  'gugum',
  'kakak',
  'yuyun',
  'randi',
  'lotek',
  'cucud',
  'ready',
  'kacai',
  'kyoko',
  'hantu',
  'banci',
  'water',
  'eplok',
  'laris',
  'fabel',
  'jedak',
  'awali',
  'iuran',
  'galib',
  'titen',
  'pajah',
  'donto',
  'keton',
  'nobar',
  'topan',
  'haget',
  'bagal',
  'ester',
  'herno',
  'mener',
  'jehoe',
  'merul',
  'leket',
  'ketik',
  'sobar',
  'diran',
  'tanji',
  'jidan',
  'aenal',
  'mutuh',
  'nutur',
  'krepa',
  'study',
  'eusam',
  'semur',
  'johan',
  'ketug',
  'willy',
  'kohok',
  'gomar',
  'cauna',
  'nurih',
  'niruk',
  'maaah',
  'geruh',
  'kalua',
  'satra',
  'jenat',
  'jumah',
  'sakai',
  'asbak',
  'harry',
  'anjun',
  'dicap',
  'zinah',
  'korah',
  'linda',
  'sizes',
  'kedua',
  'kehed',
  'mirun',
  'amrik',
  'pakta',
  'umrah',
  'hetty',
  'malin',
  'tentu',
  'elang',
  'cucun',
  'koela',
  'smule',
  'broel',
  'ancol',
  'limus',
  'manut',
  'nyamu',
  'haris',
  'puspa',
  'jejem',
  'kurun',
  'tebet',
  'ngowo',
  'diyeu',
  'ambar',
  'binti',
  'notog',
  'gahar',
  'imran',
  'gewat',
  'ngome',
  'noyod',
  'abner',
  'japan',
  'kolbu',
  'noron',
  'suria',
  'aroma',
  'puser',
  'tamah',
  'istal',
  'penuh',
  'ruyuk',
  'empuk',
  'sukan',
  'tandu',
  'direm',
  'fardu',
  'pejan',
  'tenda',
  'medit',
  'honda',
  'rebun',
  'kahat',
  'windu',
  'syiar',
  'tolab',
  'kudus',
  'kongo',
  'tamar',
  'ledok',
  'lurus',
  'youre',
  'wirid',
  'being',
  'memet',
  'nutug',
  'kulak',
  'haduh',
  'konta',
  'bonus',
  'oleng',
  'kemit',
  'sumbu',
  'cacag',
  'sadok',
  'keked',
  'sawan',
  'sadis',
  'sirib',
  'pajoe',
  'brata',
  'siger',
  'fitur',
  'unang',
  'plays',
  'jalil',
  'gigih',
  'rasti',
  'japuh',
  'sipit',
  'lenen',
  'gesik',
  'mamam',
  'anjis',
  'neken',
  'solid',
  'solar',
  'pindo',
  'anton',
  'duhur',
  'tanam',
  'genti',
  'pohon',
  'oneng',
  'pokal',
  'murwa',
  'baceo',
  'siwah',
  'kalan',
  'graha',
  'blank',
  'diyan',
  'lukas',
  'times',
  'mawas',
  'sanad',
  'yanto',
  'enjoy',
  'ading',
  'mimik',
  'dunja',
  'mules',
  'bende',
  'ghoib',
  'patya',
  'djsro',
  'sesat',
  'angke',
  'eueuh',
  'odang',
  'senam',
  'magis',
  'gadil',
  'lesna',
  'nalek',
  'pleng',
  'mubah',
  'burok',
  'murus',
  'kasir',
  'cohag',
  'tinya',
  'teuku',
  'nunun',
  'could',
  'falah',
  'tolol',
  'nggih',
  'nuluy',
  'hareu',
  'tiada',
  'lppsi',
  'njang',
  'nulad',
  'kacer',
  'kyung',
  'dasmi',
  'ohang',
  'syair',
  'momen',
  'rerep',
  'waman',
  'thing',
  'known',
  'sotja',
  '<span',
  'logak',
  'djoeh',
  'lajer',
  'koswr',
  'rinja',
  'majah',
  'balak',
  'kerud',
  'ratri',
  'mangu',
  'bulat',
  'kerah',
  'incoe',
  'joram',
  'jagad',
  'lanan',
  'norah',
  'setir',
  'husin',
  'sarna',
  'djeng',
  'nyien',
  'korup',
  'fauna',
  'barjo',
  'botak',
  'giras',
  'likes',
  'cariu',
  'maruf',
  'cetak',
  'mupul',
  'koeng',
  'mbung',
  'yasin',
  'daksa',
  'ubyag',
  'penyu',
  'close',
  'rajab',
  'teing',
  'waduh',
  'kuwat',
  'obama',
  'swiss',
  'djaba',
  'gidig',
  'whats',
  'money',
  'istan',
  'rubik',
  'topic',
  'iyeuh',
  'mandu',
  'bidji',
  'radar',
  'lukis',
  'diyuk',
  'agoes',
  'doang',
  'betel',
  'stage',
  'baran',
  'bewok',
  'razia',
  'sieta',
  'tatan',
  'fadar',
  'silib',
  'malim',
  'manus',
  'recet',
  'umbul',
  'murda',
  'dpdri',
  'pegon',
  'arile',
  'tikah',
  'sareh',
  'sigeg',
  'ciwit',
  'djawa',
  'cirri',
  'saper',
  'kiayi',
  'semen',
  'odeng',
  'benci',
  'bembo',
  'sateu',
  'uwing',
  'timor',
  'wakaf',
  'engga',
  'nyapa',
  'onder',
  'giruk',
  'ungal',
  'lanat',
  'bolot',
  'hidir',
  'iyang',
  'basan',
  'paste',
  'keler',
  'araja',
  'nging',
  'ormas',
  'keteg',
  'suhri',
  'gelis',
  'katji',
  'jelug',
  'karoe',
  'salut',
  'deuyi',
  'gerot',
  'motto',
  'kerak',
  'klien',
  'orasi',
  'cagar',
  'nggak',
  'means',
  'sakim',
  'login',
  'acube',
  'loket',
  'matek',
  'tangi',
  'habib',
  'plong',
  'klang',
  'walah',
  'tilil',
  'sanis',
  'keung',
  'blade',
  'negor',
  'garap',
  'otang',
  'haola',
  'gesit',
  'midek',
  'erana',
  'kenek',
  'mulut',
  'babut',
  'begog',
  'misja',
  'uwung',
  'gerah',
  'pelit',
  'dudus',
  'wahab',
  'spion',
  'carem',
  'turub',
  'eraan',
  'obing',
  'humas',
  'bugel',
  'mumun',
  'gauri',
  'tawon',
  'kutub',
  'kebun',
  'kaanu',
  'ngira',
  'samma',
  'adama',
  'godeg',
  'awina',
  'ambil',
  'derna',
  'netek',
  'lambe',
  'tiara',
  'linta',
  'doepi',
  'basar',
  'seret',
  'stand',
  'papap',
  'siria',
  'kunto',
  'delan',
  'wirya',
  'sorry',
  'sukon',
  'lepot',
  'mecak',
  'nyang',
  'wendi',
  'ambih',
  'kenoh',
  'royal',
  'mepes',
  'arana',
  'sudan',
  'becus',
  'bendi',
  'vidio',
  'tolih',
  'endin',
  'anyer',
  'tauco',
  'multi',
  'lesti',
  'lekoh',
  'wande',
  'haaar',
  'rojak',
  'sujen',
  'riska',
  'udzur',
  'bhasa',
  'tante',
  'malka',
  'welah',
  'seuer',
  'rarat',
  'trans',
  'antal',
  'ratoe',
  'nekuk',
  'gugus',
  'bahru',
  'rawit',
  'rudal',
  'hnteu',
  'sutan',
  'ayame',
  'lendo',
  'nanja',
  'apdet',
  'draws',
  'takut',
  'keong',
  'haman',
  'kaleb',
  'nepal',
  'jiang',
  'letik',
  'tjaja',
  'arasy',
  'mario',
  'ajeug',
  'tayub',
  'mohon',
  'tasna',
  'sarae',
  'teken',
  'liher',
  'kasdi',
  'kuran',
  'hoang',
  'jeuro',
  'sleep',
  'galah',
  'sobur',
  'nujuh',
  'pepel',
  'young',
  'aisah',
  'ayung',
  'dimas',
  'nurna',
  'night',
  'djati',
  'baper',
  'ketus',
  'molah',
  'encoh',
  'doana',
  'sewan',
  'kasup',
  'butak',
  'siwur',
  'pakel',
  'eujin',
  'megan',
  'marti',
  'warji',
  'habil',
  'samba',
  'satwa',
  'sweet',
  'kayak',
  'rahsa',
  'medja',
  'tatah',
  'lanka',
  'kikis',
  'yulis',
  'jakob',
  'uaran',
  'podol',
  'rindu',
  'natan',
  'impun',
  'kacau',
  'jaler',
  'bahar',
  'nadia',
  'artja',
  'tawes',
  'nyoco',
  'bagyo',
  'majit',
  'sense',
  'celuk',
  'rahmi',
  'laisa',
  'ditoe',
  'aidin',
  'kadus',
  'rabil',
  'jabir',
  'great',
  'ngeng',
  'tatit',
  'boreh',
  'spasi',
  'ewean',
  'mepek',
  'luwes',
  'biner',
  'reuay',
  'kapar',
  'anita',
  'pawey',
  'syiah',
  'kiper',
  'nigas',
  'egois',
  'utang',
  'titih',
  'cerik',
  'saanu',
  'prang',
  'anoet',
  'tatap',
  'polio',
  'kitts',
  'eueut',
  'nuaya',
  'ekeur',
  'ninja',
  'saati',
  'idzin',
  'cohok',
  'syahr',
  'habis',
  'gamis',
  'aduna',
  'wekas',
  'pagar',
  'wilis',
  'benua',
  'jueng',
  'kepuh',
  'yaeta',
  'nahin',
  'kauji',
  'apana',
  'pacah',
  'witir',
  'toana',
  'robby',
  'meula',
  'aksis',
  'keeap',
  'meper',
  'iedul',
  'hooor',
  'kiray',
  'andon',
  'trana',
  'darul',
  'hajoe',
  'rumit',
  'oncor',
  'marco',
  'wanti',
  'sekem',
  'ninis',
  'lanay',
  'ganca',
  'lahar',
  'hahah',
  'figur',
  'batre',
  'peter',
  'wiwit',
  'masud',
  'hasna',
  'giant',
  'momon',
  'lojor',
  'komod',
  'askar',
  'kucel',
  'rujuk',
  'wahid',
  'kleup',
  'njapa',
  'noeng',
  'kreta',
  'jacob',
  'mugni',
  'idlal',
  'these',
  'arodl',
  'salem',
  'rukim',
  'horor',
  'ireng',
  'wisma',
  'berag',
  'valid',
  'lamad',
  'malay',
  'nyait',
  'ciiik',
  'juheb',
  'dudun',
  'laman',
  'kadim',
  'segar',
  'impen',
  'kuman',
  'mewek',
  'kadie',
  'tardi',
  'lemur',
  'torah',
  'minna',
  'stres',
  'harid',
  'adzim',
  'narto',
  'datna',
  'start',
  'telor',
  'opsir',
  'ahima',
  'nyoso',
  'pepet',
  'betul',
  'balna',
  'sereh',
  'pares',
  'lidah',
  'dugem',
  'aloes',
  'lejar',
  'petit',
  'mapak',
  'hilaf',
  'biung',
  'asyar',
  'misro',
  'gmail',
  'kaolo',
  'diilo',
  'togog',
  'stkip',
  'besuk',
  'gadog',
  'irpan',
  'kaman',
  'nisan',
  'biroe',
  'susak',
  'nurus',
  'helos',
  'sabak',
  'irene',
  'dhien',
  'ritme',
  'laing',
  'seoul',
  'cahak',
  'pidio',
  'kiwil',
  'ahaya',
  'sapat',
  'gesan',
  'tutus',
  'inter',
  'uubar',
  'djaga',
  'antri',
  'coyor',
  'joglo',
  'djago',
  'piano',
  'tanab',
  'plaza',
  'budhi',
  'aleum',
  'jalal',
  'goang',
  'ngang',
  'pires',
  'jekok',
  'joget',
  'engon',
  'aksen',
  'ajeun',
  'class',
  'ateng',
  'oyong',
  'brana',
  'napuk',
  'memes',
  'lubar',
  'paraa',
  'hyong',
  'jogol',
  'jatim',
  'tipis',
  'recok',
  'katur',
  'tuban',
  'tiger',
  'lunca',
  'titel',
  'godin',
  'pitak',
  'geboy',
  'bilah',
  'tabir',
  'gatra',
  'ketat',
  'hindi',
  'aking',
  'panda',
  'kupon',
  'kuasa',
  'lijan',
  'ihrom',
  'kondi',
  'bijeu',
  'lelah',
  'metue',
  'wabah',
  'hallo',
  'speak',
  'sorog',
  'palaj',
  'hayal',
  'kemar',
  'kasja',
  'warid',
  'pipit',
  'noesa',
  'jahil',
  'dasep',
  'rival',
  'kpany',
  'soson',
  'ereng',
  'masya',
  'hotib',
  'nonoy',
  'tablo',
  'pasan',
  'mulah',
  'newek',
  'repok',
  'uarng',
  'below',
  'nular',
  'rilis',
  'dekat',
  'liyan',
  'djaan',
  'aruih',
  'punta',
  'palem',
  'kumuh',
  'parik',
  'jeket',
  'helem',
  'nahor',
  'hendy',
  'rezim',
  'taris',
  'pkpri',
  'trang',
  'sadun',
  'kaung',
  'sapan',
  'kiman',
  'badar',
  'brown',
  'cacat',
  'manca',
  'andil',
  'masal',
  'diary',
  'porsi',
  'mamum',
  'priok',
  'hapur',
  'retty',
  'awalu',
  'neneh',
  'cetuk',
  'talun',
  'smsan',
  'namru',
  'gesly',
  'merem',
  'rocky',
  'bejak',
  'mepet',
  'safar',
  'bhumi',
  'royan',
  'dinni',
  'jante',
  'pardu',
  'heong',
  'yours',
  'ggtih',
  'gigis',
  'muhyi',
  'stock',
  'opera',
  'liris',
  'harto',
  'ituna',
  'subhi',
  'asean',
  'poera',
  'tanti',
  'gindi',
  'tambi',
  'eloet',
  'euwah',
  'pepen',
  'tirem',
  'mimih',
  'mawat',
  'meber',
  'tasbe',
  'fosil',
  'legeg',
  'memek',
  'honje',
  'lucah',
  'hakam',
  'mayat',
  'icham',
  'lises',
  'nufus',
  'dapid',
  'truth',
  'suten',
  'widya',
  'cuiih',
  'uniko',
  'smart',
  'idris',
  'husni',
  'ateuh',
  'tehel',
  'julia',
  'persi',
  'might',
  'jones',
  'satya',
  'gawul',
  'danau',
  'bwana',
  'ragas',
  'asmin',
  'tarum',
  'muhal',
  'orgen',
  'dinan',
  'boeni',
  'lugas',
  'gabah',
  'hamat',
  'kokoh',
  'anmol',
  'didnt',
  'white',
  'trend',
  'sagar',
  'larik',
  'ketip',
  'mamad',
  'ikrom',
  'ghana',
  'riset',
  'josia',
  'while',
  'meila',
  'cigah',
  'matan',
  'lirih',
  'belis',
  'etnik',
  'rewok',
  'anuna',
  'pahla',
  'eprat',
  'cinde',
  'nyima',
  'pardm',
  'yunos',
  'baban',
  'genus',
  'yudha',
  'pecel',
  'nurub',
  'rakat',
  'pokus',
  'eksis',
  'engko',
  'pipin',
  'gawat',
  'mujur',
  'umeng',
  'pikeu',
  'ttpod',
  'dicet',
  'milah',
  'aslam',
  'ucweb',
  'gibon',
  'herry',
  'pukah',
  'petis',
  'pakai',
  'rodek',
  'gitik',
  'xiami',
  'euweh',
  'benin',
  'kilir',
  'modin',
  'haran',
  'yopie',
  'nisab',
  'aprak',
  'rambu',
  'ruing',
  'perut',
  'sreng',
  'gosen',
  'sepat',
  'etana',
  'induk',
  'ungel',
  'tumis',
  'upsir',
  'learn',
  'copas',
  'piket',
  'encih',
  'nokia',
  'bucat',
  'suaib',
  'antek',
  'kulhu',
  'nyela',
  'nunuy',
  'bohay',
  'makro',
  'nahyi',
  'ntong',
  'haeun',
  'epeng',
  'reres',
  'lecek',
  'momod',
  'senti',
  'milan',
  'beken',
  'pliss',
  'matic',
  'hibah',
  'hiang',
  'sport',
  'saduy',
  'pleus',
  'serem',
  'rapot',
  'bugil',
  'cloud',
  'manna',
  'sodik',
  'djasa',
  'badul',
  'tokyo',
  'inong',
  'pirig',
  'laput',
  'heeuh',
  'paseh',
  'luwar',
  'janda',
  'jetet',
  'gisik',
  'berok',
  'surut',
  'ingat',
  'harap',
  'oeria',
  'adnan',
  'datan',
  'three',
  'winda',
  'robin',
  'rahil',
  'seber',
  'comes',
  'kabut',
  'cekas',
  'janin',
  'guruh',
  'minor',
  'khair',
  'klara',
  'natra',
  'kemod',
  'brang',
  'ahead',
  'sukun',
  'aming',
  'ngaha',
  'sewot',
  'write',
  'piatu',
  'bolon',
  'kecok',
  'tsgal',
  'kutil',
  'mayak',
  'madoe',
  'djian',
  'trofy',
  'kejot',
  'celup',
  'satin',
  'lebok',
  'neluh',
  'kaboa',
  'manaf',
  'djaja',
  'irian',
  'mardi',
  'moher',
  'cleng',
  'dream',
  'kawan',
  'gebar',
  'beker',
  'sihon',
  'rasih',
  'teddi',
  'dituh',
  'engen',
  'greta',
  'paler',
  'thong',
  'insun',
  'marun',
  'tibet',
  'piece',
  'cepel',
  'derma',
  'umbar',
  'inbok',
  'burey',
  'sardp',
  'tonio',
  'buwat',
  'under',
  'prima',
  'sarai',
  'jauhi',
  'wendy',
  'kasoe',
  'gabon',
  'salia',
  'herey',
  'rewas',
  'juhan',
  'naomi',
  'kulur',
  'barma',
  'muput',
  'gilek',
  'saman',
  'asbes',
  'awian',
  'kelet',
  'kumha',
  'kadis',
  'konon',
  'gendu',
  'ironi',
  'mangr',
  'sasra',
  'besek',
  'afdol',
  'hateu',
  'denis',
  'milam',
  'umaro',
  'sarju',
  'braja',
  'leyos',
  'bedak',
  'kanak',
  'actor',
  'babuk',
  'mawon',
  'putat',
  'nifas',
  'wazir',
  'badog',
  'mommo',
  'kutud',
  'wagub',
  'dauna',
  'ajoeb',
  'darda',
  'kodok',
  'alihi',
  'uihna',
  'mispa',
  'ripos',
  'basir',
  'hanok',
  'romeo',
  'surud',
  'lajar',
  'yatna',
  'sepre',
  'komat',
  'acana',
  'gowok',
  'lurrr',
  'panik',
  'rotan',
  'gowes',
  'hokum',
  'mulan',
  'saeun',
  'kudis',
  'lepel',
  'pojok',
  'cicin',
  'afiat',
  'habsa',
  'ancam',
  'tijap',
  'aseng',
  'padat',
  'named',
  'risal',
  'kodam',
  'amnon',
  'girls',
  'pipih',
  'bayur',
  'moeka',
  'risna',
  'lasem',
  'hento',
  'hoedi',
  'nalan',
  'mommy',
  'chaer',
  'payil',
  'lilah',
  'dacep',
  'goeha',
  'kateu',
  'danny',
  'sarba',
  'suing',
  'kepek',
  'aduuh',
  'mesiu',
  'hisab',
  'nyeni',
  'mineh',
  'beaya',
  'legal',
  'niaga',
  'prodi',
  'ainil',
  'astra',
  'sinum',
  'nikos',
  'dijah',
  'lumut',
  'sarja',
  'japri',
  'cepak',
  'rebah',
  'kobul',
  'kadir',
  'besok',
  'busty',
  'fatih',
  'yunan',
  'wuluh',
  'tatas',
  'ombeh',
  'token',
  'darsa',
  'golep',
  'jidah',
  'nenen',
  'badai',
  'gerry',
  'jamad',
  'manja',
  'kagoe',
  'zaini',
  'gagat',
  'comel',
  'tjing',
  'tipes',
  'adapt',
  'pasun',
  'raeng',
  'taram',
  'bekok',
  'syari',
  'ragag',
  'butet',
  'cager',
  'kagum',
  'cepuk',
  'yoman',
  'peser',
  'labas',
  'jreng',
  'tekun',
  'aseuk',
  'rewah',
  'marap',
  'lusia',
  'emisi',
  'monas',
  'kuren',
  'boobs',
  'melek',
  'senar',
  'juhro',
  'wahai',
  'amlra',
  'dolar',
  'rewel',
  'medok',
  'saibu',
  'bohim',
  'nadom',
  'karan',
  'hmmmh',
  'gotra',
  'reuni',
  'pudak',
  'maido',
  'ngogo',
  'notok',
  'icing',
  'urban',
  'aseli',
  'kepas',
  'ferry',
  'badru',
  'nehna',
  'adeuh',
  'kukuk',
  'hajah',
  'atrok',
  'luluh',
  'raffi',
  'gubug',
  'cetok',
  'ramai',
  'kuasi',
  'kurda',
  'ngawi',
  'tipar',
  'nazar',
  'ilyas',
  'dewal',
  'sepah',
  'tower',
  'aksan',
  'asgar',
  'darta',
  'adjar',
  'kaper',
  'jadol',
  'space',
  'batam',
  'turga',
  'picis',
  'pasna',
  'panel',
  'kange',
  'hapit',
  'catra',
  'tegar',
  'nahas',
  'riben',
  'watan',
  'kembu',
  'lipen',
  'ummah',
  'adung',
  'riwan',
  'hayuh',
  'zikir',
  'galon',
  'bunar',
  'igana',
  'hobby',
  'aurat',
  'murel',
  'patra',
  'flexi',
  'fatah',
  'hiyap',
  'duuuh',
  'nalak',
  'atoeh',
  'ampih',
  'bokep',
  'embel',
  'rigig',
  'leman',
  'pelik',
  'butir',
  'prada',
  'canon',
  'kalla',
  'tegak',
  'mesti',
  'gepuk',
  'kocok',
  'ngagu',
  'incue',
  'atlit',
  'pinal',
  'kebel',
  'mouse',
  'hajji',
  'barik',
  'nasir',
  'joled',
  'djiga',
  'frase',
  'heard',
  'taneh',
  'kirik',
  'hegak',
  'kaaku',
  'aprok',
  'njaur',
  'arini',
  'yuyus',
  'garan',
  'troas',
  'hirna',
  'hadma',
  'gardu',
  'belel',
  'didua',
  'jajat',
  'patuk',
  'rekor',
  'benno',
  'kagak',
  'trias',
  'hijah',
  'ieuna',
  'hanna',
  'maotu',
  'cause',
  'parta',
  'beras',
  'mapaj',
  'wakul',
  'homes',
  'dajal',
  'natal',
  'basil',
  'melas',
  'kutip',
  'omean',
  'barak',
  'maaka',
  'nilep',
  'ancad',
  'light',
  'jutta',
  'nyeot',
  'muser',
  'ardan',
  'blues',
  'rasio',
  'penny',
  'parwa',
  'tahir',
  'mouth',
  'talen',
  'dalan',
  'casdi',
  'ropoh',
  'kodim',
  'bakoe',
  'illaa',
  'kenya',
  'let,s',
  'tetes',
  'harum',
  'nadab',
  'kokar',
  'shoes',
  'maray',
  'lapan',
  'heboh',
  'rejim',
  'fikir',
  'kusim',
  'buled',
  'jigna',
  'ngawa',
  'becak',
  'dprri',
  'husep',
  'mirna',
  'buyar',
  'hemor',
  'jibja',
  'lekra',
  'ejaan',
  'madju',
  'kebet',
  'balay',
  'aswad',
  'bibir',
  'nodel',
  'cerah',
  'ayank',
  'kelom',
  'kesik',
  'perti',
  'rdman',
  'nyual',
  'naona',
  'roeng',
  'toket',
  'madri',
  'sagir',
  'mahan',
  'nagel',
  'kesih',
  'bandi',
  'booto',
  'tilep',
  'lucia',
  'wrong',
  'jeler',
  'serek',
  'widdy',
  'unsil',
  'griya',
  'engal',
  'sampo',
  'ferdu',
  'atuhh',
  'handy',
  'pones',
  'dinah',
  'berem',
  'kapoe',
  'rahab',
  'ksrsa',
  'wslas',
  'sites',
  'tatih',
  'panya',
  'zarah',
  'natar',
  'maueh',
  'sirih',
  'diyah',
  'gning',
  'klise',
  'arkan',
  'kanza',
  'nitir',
  'enter',
  'mubuy',
  'nipis',
  'gerwa',
  'firda',
  'dcung',
  'okley',
  'waled',
  'pecug',
  'iceus',
  'kosar',
  'syifa',
  'aboks',
  'kahfi',
  'walau',
  'hiram',
  'ngacu',
  'index',
  'gacor',
  'njing',
  'kasdu',
  'baksa',
  'samin',
  'turks',
  'jenug',
  'pijat',
  'anten',
  'agnes',
  'retna',
  'tenis',
  'bajir',
  'berea',
  'pecle',
  'mirak',
  'celep',
  'butub',
  'holna',
  'ngear',
  'garam',
  'tauna',
  'bruno',
  'peres',
  'pardp',
  'murai',
  'jaksi',
  'seung',
  'teler',
  'struk',
  'taina',
  'boten',
  'kinca',
  'shari',
  'taruk',
  'tjaah',
  'jenuh',
  'katak',
  'sanip',
  'tulah',
  'samen',
  'block',
  'powek',
  'madia',
  'bohak',
  'muris',
  'tarok',
  'pejah',
  'helas',
  'ewang',
  'pasip',
  'isuna',
  'ragen',
  'jahim',
  'bocah',
  'pulan',
  'isina',
  'timna',
  'rurug',
  'sabit',
  'nyong',
  'kubah',
  'dikri',
  'bajra',
  'jejek',
  'kumed',
  'nguah',
  'terak',
  'walet',
  'tohir',
  'baasa',
  'bosan',
  'jeroe',
  'japar',
  'rawan',
  'ayeuh',
  'nyaaa',
  'muril',
  'tunil',
  'ricek',
  'gojek',
  'bager',
  'wahua',
  'kurma',
  'ihung',
  'sumut',
  'telur',
  'ha,ha',
  'gagas',
  'pijet',
  'sajim',
  'njata',
  'ragab',
  'nerag',
  'rekan',
  'coret',
  'limun',
  'jeugn',
  'gejed',
  'ninga',
  'ontan',
  'halil',
  'rarna',
  'waria',
  'dipak',
  'sedap',
  'ndien',
  'selpi',
  'ketut',
  'women',
  'luwuk',
  'rusli',
  'gihan',
  'benet',
  'detil',
  'iming',
  'uswah',
  'campa',
  'sgdja',
  'denny',
  'alert',
  'hisop',
  'jalla',
  'pahan',
  'rurat',
  'yosua',
  'aleut',
  'robul',
  'pedet',
  'bible',
  'nahna',
  'round',
  'jabes',
  'carry',
  'cairo',
  'looks',
  'neran',
  'darus',
  'demba',
  'negro',
  'curak',
  'holil',
  'kezia',
  'ngahu',
  'kowak',
  'moeng',
  'billa',
  'badui',
  'encit',
  'puhun',
  'idena',
  'akrob',
  'ngasa',
  'naura',
  'mgsir',
  'matre',
  'gutak',
  'pelok',
  'nenda',
  'loyal',
  'kanti',
  'troes',
  'tikeu',
  'nuril',
  'obyag',
  'wawar',
  'gaper',
  'inonk',
  'kawis',
  'hitar',
  'leung',
  'yulia',
  'gawok',
  'state',
  'rateg',
  'plasa',
  'weung',
  'nyiap',
  'kabid',
  'gotik',
  'kucai',
  'corry',
  'tisuk',
  'tamim',
  'tajib',
  'bubun',
  'mapat',
  'helmi',
  'poros',
  'iddah',
  'yeuna',
  'kgbon',
  'third',
  'sajen',
  'mamon',
  'cawad',
  'nempa',
  'wacis',
  'durat',
  'eatur',
  'reuah',
  'saneh',
  'jeans',
  'suhun',
  'orbit',
  'stone',
  'rinso',
  'sarga',
  'moden',
  'gamar',
  'embol',
  'kadia',
  'kakek',
  'pulut',
  'bilal',
  'bajar',
  'ubrug',
  'riadi',
  'jejak',
  'kampa',
  'seluk',
  'pikul',
  'oasis',
  'totem',
  'sagal',
  'jaanu',
  'tusuk',
  'umaku',
  'lengo',
  'gejos',
  'hadji',
  'gokil',
  'oesia',
  'dosis',
  'daksi',
  'hentu',
  'urung',
  'karso',
  'reyod',
  'brong',
  'jenar',
  'terns',
  'ijang',
  'nyoro',
  'slami',
  'jenuk',
  'cowok',
  'geder',
  'lalat',
  'euwuh',
  'mahir',
  'ilusi',
  'yosia',
  'nyeak',
  'kibik',
  'widia',
  'suker',
  'nobel',
  'jelek',
  'ragan',
  'simut',
  'danan',
  'tense',
  'colek',
  'tobas',
  'salih',
  'ungut',
  'arnon',
  'iaaah',
  'moksa',
  'dinur',
  'kacap',
  'himar',
  'setha',
  'debus',
  'kalow',
  'sumba',
  'nemen',
  'bagia',
  'nimpa',
  'buluk',
  'cunha',
  'nogel',
  'nyate',
  'dilam',
  'tigas',
  'karto',
  'paray',
  'gilap',
  'dress',
  'melet',
  'suasa',
  'claim',
  'dudut',
  'nejeh',
  'leone',
  'jalam',
  'umbal',
  'firid',
  'burut',
  'hosea',
  'depth',
  'jomin',
  'gstih',
  'pandi',
  'praja',
  'sokna',
  'pedro',
  'kroya',
  'madak',
  'kadya',
  'suaka',
  'jatma',
  'bibih',
  'dapil',
  'mamre',
  'gesen',
  'minim',
  'kalap',
  'saeni',
  'merik',
  'akmal',
  'lapal',
  'djiwa',
  'pejet',
  'garba',
  'loeng',
  'ikuti',
  'atena',
  'slide',
  'tipeu',
  'intro',
  'kuswa',
  'pasak',
  'ledek',
  'whose',
  'rates',
  'disri',
  'jumri',
  'riyab',
  'nabot',
  'makes',
  'omega',
  'gasab',
  'dhuan',
  'khauf',
  'didit',
  'limit',
  'merdu',
  'tinta',
  'semit',
  'necis',
  'bedol',
  'kunta',
  'lumar',
  'saing',
  'nyugu',
  'maybe',
  'anget',
  'tours',
  'epron',
  'rekam',
  'ngaja',
  'cucul',
  'musti',
  'bupet',
  'sadap',
  'diani',
  'kuled',
  'calin',
  'idiot',
  'patan',
  'shaun',
  'allab',
  'ampel',
  'cukul',
  'nasab',
  'beung',
  'kpanu',
  'tiron',
  'nesia',
  'acong',
  'rhoma',
  'pustu',
  'embat',
  'kusia',
  'umilu',
  'ririn',
  'jsbna',
  'betok',
  'kabok',
  'nelek',
  'sarci',
  'encim',
  'nilam',
  'dirut',
  'wakyu',
  'syare',
  'totol',
  'cagap',
  'cacar',
  'derep',
  'asdod',
  'lanta',
  'padah',
  'enyus',
  'karun',
  'rejag',
  'jetot',
  'riwih',
  'ublag',
  'semak',
  'gengp',
  'sales',
  'nihan',
  'dekah',
  'kareo',
  'mizan',
  'tayoh',
  'lakis',
  'matra',
  'nyuat',
  'cikao',
  'nakal',
  'sailj',
  'ainun',
  'empar',
  'nipah',
  'rujad',
  'njaba',
  'jamil',
  'leima',
  'rukma',
  'dinih',
  'muwih',
  'crazy',
  'ening',
  'husss',
  'dilak',
  'kasum',
  'rusuk',
  'resty',
  'lezat',
  'pekik',
  'rungu',
  'oemar',
  'bolor',
  'rizal',
  'barus',
  'regas',
  'carik',
  'cabai',
  'cream',
  'lapuk',
  'tihul',
  'broek',
  'death',
  'jompo',
  'likna',
  'leste',
  'remot',
  'hoeun',
  'hotts',
  'ricit',
  'nyiku',
  'basic',
  'kopak',
  'sejuk',
  'renda',
  'iuhan',
  'jepit',
  'matin',
  'padil',
  'tommy',
  'oplet',
  'tapas',
  'celak',
  'remet',
  'jaung',
  'nisfu',
  'thoat',
  'suton',
  'uncle',
  'nesas',
  'sadut',
  'lidig',
  'migas',
  'wijak',
  'klari',
  'timun',
  'piyas',
  'dalih',
  'paras',
  'rebon',
  'rubin',
  'anung',
  'plung',
  'maska',
  'leter',
  'masar',
  'taoeu',
  'etnic',
  'cinto',
  'tjida',
  'isian',
  'latih',
  'asera',
  'walik',
  'momoh',
  'viral',
  'mikal',
  'hurik',
  'geode',
  'brand',
  'supri',
  'naela',
  'djaya',
  'makir',
  'cipto',
  'warsa',
  'nafsi',
  'laken',
  'ngeut',
  'woman',
  'idofi',
  'rabut',
  'genta',
  'apdol',
  'uning',
  'laila',
  'heads',
  'stick',
  'pa—an',
  'marki',
  'harom',
  'alarm',
  'trick',
  'rohis',
  'kahan',
  'teupa',
  'lakoe',
  'eneas',
  'donna',
  'ninih',
  'telak',
  'pipis',
  'hadad',
  'kuntu',
  'jumsa',
  'rawah',
  'pucus',
  'wadas',
  'puger',
  'kaana',
  'miras',
  'satar',
  'simri',
  'lilih',
  'guher',
  'essay',
  'menca',
  'karem',
  'merod',
  'hante',
  'malta',
  'asset',
  'edass',
  'cecel',
  'waten',
  'ariuh',
  'salaf',
  'ngoja',
  'vital',
  'print',
  'zemin',
  'sutia',
  'guide',
  'taudz',
  'tebal',
  'polri',
  'boyot',
  'jinem',
  'yonas',
  'spore',
  'rageg',
  'grogi',
  'dogma',
  'momok',
  'panet',
  'sadul',
  'sedia',
  'grote',
  'kores',
  'bewos',
  'nyasu',
  'plato',
  'fulan',
  'lanud',
  'bhima',
  'dinta',
  'tokat',
  'egang',
  'setum',
  'gedig',
  'sosog',
  'telek',
  'rasid',
  'assas',
  'gaiss',
  'lagas',
  'pulia',
  'entit',
  'hamin',
  'tarub',
  'oelab',
  'nusuk',
  'tonga',
  'taroh',
  'hasta',
  'syiwa',
  'jumpa',
  'manak',
  'husir',
  'angsu',
  'ciyos',
  'kemal',
  'inses',
  'uinsa',
  'pikoe',
  'diham',
  'works',
  'ngewe',
  'dubes',
  'melid',
  'cekes',
  'ngaba',
  'ambal',
  'omats',
  'pendi',
  'mummy',
  'greus',
  'dakna',
  'saidi',
  'denby',
  'ngung',
  'wahan',
  'herna',
  'peace',
  'amino',
  'xenia',
  'selam',
  'peluh',
  'goods',
  'kaieu',
  'raang',
  'tenan',
  'toena',
  'sasta',
  'bazar',
  'wesel',
  'benta',
  'caduk',
  'sekre',
  'senja',
  'gegep',
  'purna',
  'pinan',
  'zuhud',
  'sajan',
  'setda',
  'abror',
  'nompo',
  'empet',
  'fikih',
  'mirad',
  'siiip',
  'dalbo',
  'lamba',
  'tiras',
  'sidem',
  'melis',
  'ipeut',
  'ramot',
  'upluk',
  'kusna',
  'kedap',
  'ranti',
  'kudua',
  'sosis',
  'ratek',
  'jrung',
  'maena',
  'anteh',
  'sepon',
  'dreum',
  'vonis',
  'mabes',
  'kenan',
  'codet',
  'ngisi',
  'alien',
  'jedar',
  'libya',
  'kakat',
  'nyiak',
  'ammin',
  'munah',
  'azasi',
  'kajen',
  'panzi',
  'lahun',
  'iteng',
  'hibat',
  'ekron',
  'rayon',
  'paraf',
  'kaitu',
  'daeuk',
  'cowet',
  'manya',
  'tuuuh',
  'bilaa',
  'linen',
  'yason',
  'error',
  'kring',
  'kodek',
  'lamek',
  'karst',
  'tantu',
  'boedi',
  'jegug',
  'bakso',
  'murti',
  'jolok',
  'peuti',
  'erick',
  'curah',
  'samad',
  'naser',
  'encok',
  'goler',
  'kodar',
  'endag',
  'kiser',
  'onlen',
  'pangu',
  'popok',
  'taraf',
  'antum',
  'ahzan',
  'bento',
  'trong',
  'derbe',
  'kojal',
  'niger',
  'tajil',
  'pekah',
  'nisbi',
  'begig',
  'lahna',
  'oejah',
  'genap',
  'kerna',
  'wardi',
  'dekul',
  'giang',
  'emoet',
  'ancal',
  'nabal',
  'adian',
  'tunai',
  'yeuhh',
  'merih',
  'nyaci',
  'gapay',
  'djing',
  'teupi',
  'alaih',
  'rayak',
  'canda',
  'dandi',
  'lasun',
  'levis',
  'affan',
  'rompi',
  'netes',
  'aebna',
  'ahrom',
  'extra',
  'holan',
  'indie',
  'milka',
  'ibnul',
  'zarot',
  'lobak',
  'ameuk',
  'kutai',
  'ruwed',
  'nemal',
  'iyong',
  'menir',
  'jotam',
  'doeng',
  'books',
  'majik',
  'dicek',
  'nahwu',
  'lodeh',
  'daman',
  'ngaub',
  'meuri',
  'onyon',
  'salwa',
  'tebel',
  'canir',
  'totos',
  'jojon',
  'ultah',
  'henry',
  'peung',
  'sauma',
  'hasep',
  'aayan',
  'anila',
  'yetty',
  'koboy',
  'jakal',
  'bebeb',
  'pocer',
  'kumna',
  'laaah',
  'remek',
  'rambo',
  'angur',
  'notor',
  'hakul',
  'kedai',
  'ngaro',
  'koang',
  'emeng',
  'liyar',
  'jicir',
  'keper',
  'parts',
  'rajia',
  'nijat',
  'kacua',
  'autos',
  'since',
  'ka—an',
  'katua',
  'anter',
  'ihwan',
  'ruhna',
  'mupuk',
  'silpa',
  'pedas',
  'maari',
  'eenah',
  'tirsa',
  'gugut',
  'salto',
  'suduk',
  'hayem',
  'tobia',
  'speed',
  'lebay',
  'rikeu',
  'nojer',
  'hayap',
  'siieu',
  'molek',
  'sohib',
  'sigeu',
  'ngage',
  'punar',
  'sepen',
  'kalur',
  'katna',
  'lotre',
  'jahal',
  'sdsmp',
  'wilet',
  'inggu',
  'madat',
  'ruket',
  'ikbal',
  'reket',
  'larak',
  'padoe',
  'kenop',
  'medem',
  'umang',
  'rasmi',
  'pinus',
  'buwah',
  'jayus',
  'suhro',
  'deuim',
  'cibeo',
  'gabus',
  'areal',
  'width',
  'camar',
  'mumuh',
  'asyik',
  'eskul',
  'klaim',
  'duafa',
  'elpis',
  'diieu',
  'entah',
  'simba',
  'nieun',
  'hoyah',
  'kamsi',
  'bocek',
  'camal',
  'kekeb',
  'lagih',
  'lodra',
  'zezen',
  'trust',
  'ulung',
  'aisha',
  'sorak',
  'tadji',
  'teuel',
  'puhan',
  'setar',
  'bocel',
  'ranna',
  'dores',
  'nyali',
  'gunug',
  'gebur',
  'bilha',
  'durji',
  'eunya',
  'kusek',
  'lewis',
  'caluk',
  'garin',
  'bohna',
  'stars',
  'oblag',
  'ririh',
  'melon',
  'ngago',
  'twitt',
  'tawil',
  'landi',
  'ainal',
  'koong',
  'rajen',
  'ngoem',
  'rendy',
  'paijo',
  'gugel',
  'yudis',
  'yazid',
  'raras',
  'geget',
  'penis',
  'ramli',
  'faruk',
  'insha',
  'ihram',
  'magol',
  'rawek',
  'tukul',
  'teter',
  'nebar',
  'encum',
  'neger',
  'rofik',
  'sapri',
  'lemot',
  'tilok',
  'gandi',
  'porak',
  'lepet',
  'diass',
  'gandu',
  'lalap',
  'debut',
  'dinua',
  'bagas',
  'gujur',
  'japra',
  'cabul',
  'seleh',
  'rojab',
  'nasep',
  'sadel',
  'purut',
  'banen',
  'ridha',
  'hahaa',
  'layer',
  'sowek',
  'duleh',
  'legon',
  'gusur',
  'ancak',
  'pupul',
  'entut',
  'dodoy',
  'khofi',
  'bubuh',
  'poelo',
  'lunga',
  'pepes',
  'polda',
  'riged',
  'jasim',
  'ngabo',
  'nyita',
  'kurus',
  'jerat',
  'buluh',
  'durga',
  'maren',
  'sisih',
  'mercy',
  'bodjo',
  'aruji',
  'bunyi',
  'hands',
  'imsak',
  'ngoer',
  'praya',
  'dudua',
  'sirap',
  'kmaha',
  'nunur',
  'tamam',
  'muria',
  'haaah',
  'kumar',
  'jekuk',
  'wuwus',
  'kinan',
  'casan',
  'mecat',
  'kerti',
  'kelak',
  'lahem',
  'fanta',
  'rekah',
  'celah',
  'parsi',
  'libna',
  'tajul',
  'burma',
  'siska',
  'scene',
  'oorok',
  'puhit',
  'sukla',
  'fahry',
  'liput',
  'ceria',
  'karen',
  'sakeu',
  'silip',
  'kufro',
  'akasa',
  'zuber',
  'tebus',
  'halow',
  'esnat',
  'naman',
  'gogon',
  'gonna',
  'absar',
  'watgs',
  'hanan',
  'masha',
  'hampa',
  'kenah',
  'giung',
  'rekna',
  'heroy',
  'nujun',
  'bulen',
  'hapus',
  'bosih',
  'humut',
  'maksi',
  'holis',
  'busuk',
  'babeh',
  'linux',
  'amram',
  'ummun',
  'sajum',
  'behel',
  'obeng',
  'juwar',
  'wareh',
  'cakah',
  'idrus',
  'pileg',
  'kibor',
  'farji',
  'mopok',
  'pahru',
  'ngupi',
  'widak',
  'hasor',
  'jorag',
  'kufah',
  'sakum',
  'diisi',
  'karuh',
  'aplak',
  'tempa',
  'rogok',
  'halte',
  'diopi',
  'sebit',
  'ngota',
  'dirty',
  'nylon',
  'ruyat',
  'sholi',
  'getir',
  'plump',
  'njoba',
  'garpu',
  'lonte',
  'pudin',
  'insta',
  'hanet',
  'disoe',
  'agrem',
  'dokar',
  'infus',
  'imang',
  'cupet',
  'tawur',
  'peret',
  'maida',
  'cawal',
  'eboed',
  'libuk',
  'ewena',
  'lahat',
  'treng',
  'nisaa',
  'ahmid',
  'lines',
  'kwaci',
  'badju',
  'kelor',
  'pikat',
  'kasta',
  'fatal',
  'babna',
  'gibas',
  'toren',
  'apaan',
  'pause',
  'eleuh',
  'parbu',
  'comal',
  'gelam',
  'tenar',
  'finis',
  'horas',
  'jatuk',
  'rabbi',
  'subab',
  'jebol',
  'cadel',
  'beben',
  'lobby',
  'rayap',
  'landa',
  'sudin',
  'welit',
  'resto',
  'aring',
  'dedes',
  'entuy',
  'satai',
  'megeg',
  'caita',
  'raman',
  'vespa',
  'geuri',
  'atuuh',
  'intim',
  'copel',
  'arera',
  'iiiih',
  'sasma',
  'erang',
  'waina',
  'yandu',
  'tumpa',
  'jammu',
  'bursa',
  'dendi',
  'halid',
  'bigal',
  'shoot',
  'sumah',
  'angus',
  'cobek',
  'nyabu',
  'haraf',
  'pasek',
  'soheh',
  'burrr',
  'somud',
  'sahut',
  'hosna',
  'badja',
  'endut',
  'wayne',
  'antuk',
  'asang',
  'anhum',
  'tummy',
  'jajal',
  'cekol',
  'napet',
  'goals',
  'songo',
  'mimis',
  'asila',
  'hukun',
  'puber',
  'solih',
  'palah',
  'ibina',
  'dikoe',
  'wasna',
  'kasia',
  'teror',
  'toyib',
  'ujaku',
  'kerok',
  'dieta',
  'bromo',
  'lubak',
  'worry',
  'sirod',
  'cewaw',
  'harli',
  'pioen',
  'santy',
  'angan',
  'yeaah',
  'kosal',
  'pijut',
  'didji',
  'alina',
  'patik',
  'sarng',
  'teran',
  'peron',
  'miley',
  'berek',
  'urnag',
  'getah',
  'niban',
  'candy',
  'soran',
  'polet',
  'aboes',
  'matat',
  'naroh',
  'mundu',
  'sukro',
  'sebra',
  'kapol',
  'gogod',
  'kanya',
  'zipur',
  'natus',
  'sihat',
  'hatim',
  'cupat',
  'lotro',
  'jaham',
  'lulur',
  'jjeng',
  'trung',
  'njeri',
  'emung',
  'ebook',
  'ngare',
  'aroer',
  'waala',
  'jenin',
  'tinon',
  'lekek',
  'rekha',
  'sonya',
  'sarwo',
  'tarju',
  'rosid',
  'jinsi',
  'sarac',
  'indus',
  'demas',
  'hilal',
  'larut',
  'nasio',
  'duket',
  'luruh',
  'karni',
  'boasa',
  'nadea',
  'tibar',
  'anuar',
  'tukar',
  'amasa',
  'leinz',
  'denuh',
  'inner',
  'embut',
  'shalt',
  'sayap',
  'gugun',
  'pusan',
  'nahnu',
  'kelik',
  'dileu',
  'corda',
  'buara',
  'poris',
  'dhaif',
  'endor',
  'apong',
  'ireug',
  'bakna',
  'ummul',
  'annur',
  'sangi',
  'clung',
  'fihak',
  'kikir',
  'gedok',
  'triak',
  'suruh',
  'ukeun',
  'butna',
  'sapar',
  'poeng',
  'sanos',
  'smsna',
  'sukar',
  'sobri',
  'lolot',
  'bebet',
  'carey',
  'tjiri',
  'radit',
  'remus',
  'sukai',
  'mepeg',
  'dunas',
  'eurad',
  'deker',
  'bahur',
  'kwari',
  'lexus',
  'hiran',
  'mifka',
  'sipak',
  'retta',
  'gayur',
  'igbah',
  'nudju',
  'ciluk',
  'dosol',
  'aalur',
  'until',
  'darya',
  'malai',
  'kubra',
  'kunir',
  'betis',
  'wafil',
  'aneng',
  'cares',
  'tjian',
  'pasai',
  'obral',
  'rakna',
  'layur',
  'angle',
  'nyaet',
  'sgala',
  'damri',
  'nakir',
  'kawul',
  'udawa',
  'ehoed',
  'wayan',
  'rebus',
  'rahuh',
  'syits',
  'sitem',
  'handi',
  'nambo',
  'poems',
  'sahwi',
  'tmpat',
  'poppy',
  'kasak',
  'hisap',
  'aplot',
  'alone',
  'garna',
  'utrak',
  'pawit',
  'garit',
  'diper',
  'kejam',
  'ungsi',
  'brush',
  'menes',
  'reksa',
  'mapai',
  'stake',
  'tjape',
  'ngabe',
  'kings',
  'pahit',
  'jakan',
  'delek',
  'wukir',
  'uceng',
  'sweep',
  'lapas',
  'padmi',
  'saian',
  'sirri',
  'tajuk',
  'tolib',
  'muwuh',
  'wesss',
  'umbut',
  'wilde',
  'entik',
  'pirid',
  'sapeu',
  'dhuha',
  'jahit',
  'silis',
  'gaess',
  'piyik',
  'jumsi',
  'ordeu',
  'semir',
  'demen',
  'roens',
  'sanja',
  'dudin',
  'genan',
  'jawan',
  'prasa',
  'jigah',
  'yaris',
  'tamli',
  'delri',
  'yoses',
  'juwet',
  'watss',
  'sania',
  'risih',
  'meung',
  'peleg',
  'missi',
  'listi',
  'bully',
  'didja',
  'hitup',
  'tanga',
  'limas',
  'lagoa',
  'wjlrc',
  'meski',
  'coach',
  'perga',
  'fayak',
  'depag',
  'raris',
  'lapat',
  'lumba',
  'labil',
  'diang',
  'cetek',
  'cindy',
  'ujlah',
  'lidia',
  'wetna',
  'gusar',
  'tjoba',
  'limar',
  'dasim',
  'bejer',
  'handa',
  'essey',
  'bumen',
  'heber',
  'isbak',
  'paruh',
  'purek',
  'diwoe',
  'tunga',
  'takan',
  'pirus',
  'merat',
  'atsar',
  'diome',
  'gerem',
  'kurai',
  'saasa',
  'skitu',
  'sigep',
  'ahaha',
  'cayut',
  'pawei',
  'lemor',
  'kilab',
  'jalar',
  'encuy',
  'hegel',
  'sekda',
  'saloa',
  'nakon',
  'sopir',
  'honey',
  'ngeuh',
  'iskan',
  'selah',
  'lodor',
  'cocos',
  'rapor',
  'katoe',
  'numut',
  'lajak',
  'eadja',
  'woela',
  'rimon',
  'parit',
  'ribet',
  'athus',
  'togel',
  'digan',
  'mendo',
  'tulas',
  'xiomi',
  'varia',
  'pasta',
  'rumor',
  'jegoh',
  'mijan',
  'isbah',
  'dugna',
  'luyna',
  'atung',
  'tayar',
  'nebat',
  'junan',
  'hudan',
  'kuwiz',
  'bharu',
  'ruhun',
  'keday',
  'cekah',
  'endan',
  'arjim',
  'kecet',
  'suffi',
  'siaaa',
  'nurin',
  'meteo',
  'uring',
  'kabag',
  'ianah',
  'jerih',
  'saeur',
  'hiiji',
  'bukri',
  'noker',
  'jlema',
  'anglo',
  'badug',
  'pagon',
  'yusur',
  'jiban',
  'tekor',
  'atlas',
  'aaahh',
  'sulam',
  'pundi',
  'ngkin',
  'pajun',
  'marry',
  'choir',
  'gedah',
  'yebus',
  'ayeun',
  'aduhh',
  'katem',
  'meped',
  'ngaju',
  'cecek',
  'sahya',
  'tayin',
  'insap',
  'disop',
  'cukai',
  'raran',
  'barek',
  'sardm',
  'lotto',
  'njiar',
  'enyom',
  'cpoho',
  'dudeh',
  'kesra',
  'margk',
  'keten',
  'lenny',
  'legig',
  'detya',
  'rinik',
  'asiik',
  'soele',
  'milad',
  'dirdm',
  'halta',
  'corob',
  'adjat',
  'susut',
  'aroem',
  'karti',
  'skema',
  'heung',
  'pardl',
  'latta',
  'ghara',
  'mulak',
  'parto',
  'trima',
  'intgn',
  'ditja',
  'dusta',
  'sahih',
  'pista',
  'gebru',
  'pakis',
  'wanna',
  'wwang',
  'kanja',
  'minar',
  'tuker',
  'sisik',
  'steel',
  'sunni',
  'hujah',
  'gaduk',
  'emaus',
  'pakoe',
  'horeb',
  'pubuh',
  'hijar',
  'panco',
  'kadan',
  'cikih',
  'ulupi',
  'kurng',
  'doddy',
  'gular',
  'tjoel',
  'aswin',
  'pitik',
  'katuk',
  'micen',
  'banas',
  'naran',
  'mocca',
  'hafid',
  'entry',
  'silba',
  'djaka',
  'kadin',
  'setel',
  'ranji',
  'busur',
  'paieu',
  'didie',
  'masik',
  'sahal',
  'ulina',
  'unpar',
  'lalam',
  'dipig',
  'diban',
  'melag',
  'ikeun',
  'sepan',
  'lakum',
  'orkes',
  'lulut',
  'omang',
  'getuk',
  'party',
  'euyyy',
  'volly',
  'ungga',
  'tania',
  'taban',
  'sahil',
  'lazim',
  'kuing',
  'sauna',
  'repet',
  'smpna',
  'tapal',
  'belom',
  'godos',
  'haren',
  'ibroh',
  'purbo',
  'akian',
  'mamak',
  'coboy',
  'kairo',
  'bobon',
  'henni',
  'lebat',
  'denci',
  'silok',
  'hirau',
  'piwoe',
  'nakku',
  'rabak',
  'kucek',
  'elita',
  'paren',
  'gituh',
  'perte',
  'teuna',
  'ikmal',
  'dipae',
  'older',
  'gojin',
  'kuali',
  'donor',
  'berto',
  'pidji',
  'hendi',
  'bakus',
  'diwus',
  'kidan',
  'eceng',
  'manda',
  'edged',
  'ngabi',
  'selai',
  'bajeg',
  'aslan',
  'tinga',
  'pesat',
  'euina',
  'samah',
  'kpana',
  'tansp',
  'mahli',
  'tajub',
  'akhfa',
  'rumpu',
  'anlaa',
  'cokro',
  'oerna',
  'aneda',
  'timus',
  'kelun',
  'noise',
  'nilon',
  'kicik',
  'marfu',
  'cosmo',
  'rehab',
  'drone',
  'derus',
  'hayng',
  'spoke',
  'steak',
  'pltsa',
  'isora',
  'sindi',
  'ijnil',
  'bawel',
  'rojer',
  'sario',
  'iptek',
  'sahab',
  'numat',
  'wirog',
  'tasya',
  'kucap',
  'dideu',
  'mutia',
  'mafia',
  'abuna',
  'somay',
  'bihun',
  'dolan',
  'tarud',
  'cacan',
  'lekas',
  'vikar',
  'binya',
  'build',
  'bismi',
  'karut',
  'teher',
  'juice',
  'adele',
  'ipong',
  'narok',
  'nyeor',
  'cstcp',
  'rekes',
  'saris',
  'popup',
  'gulak',
  'tutor',
  'sujai',
  'pasha',
  'lekat',
  'lindu',
  'abnos',
  'birth',
  'pudar',
  'aenil',
  'enzim',
  'aroen',
  'dusan',
  'lotte',
  'nugel',
  'hades',
  'arema',
  'yapip',
  'haeti',
  'strip',
  'atasi',
  'repit',
  'oseng',
  'eukeu',
  'satja',
  'curek',
  'sural',
  'nagis',
  'nicak',
  'athur',
  'tuhon',
  'pocng',
  'mansa',
  'menga',
  'betan',
  'walaa',
  'bunya',
  'omber',
  'kecoh',
  'natah',
  'kemir',
  'nikel',
  'rakus',
  'ksara',
  'agra|',
  'namba',
  'ketit',
  'beser',
  'jijik',
  'neung',
  'kaibu',
  'omomg',
  'mayok',
  'sieuh',
  'pagan',
  'misna',
  'scale',
  'dtema',
  'pyare',
  'dadih',
  'iwari',
  'ditim',
  'berry',
  'taguh',
  'talim',
  'desta',
  'banya',
  'badra',
  'bebel',
  'jwung',
  'holid',
  'sauce',
  'garda',
  'nukil',
  'guest',
  'sambu',
  'liver',
  'tawap',
  'hahay',
  'tekah',
  'awara',
  'pilit',
  'ariya',
  'hunde',
  'badem',
  'sofia',
  'masai',
  'djung',
  'patin',
  'bisaa',
  'walid',
  'mikul',
  'erosi',
  'laeun',
  'luigi',
  'doakn',
  'eamat',
  'gerna',
  'muham',
  'matik',
  'abdus',
  'galin',
  'seduh',
  'serut',
  'adhom',
  'ogres',
  'akuna',
  'homan',
  'ledeg',
  'dulak',
  'priyo',
  'yadel',
  'meleg',
  'nanon',
  'amran',
  'infak',
  'libia',
  'robet',
  'saren',
  'gurus',
  'yamin',
  'galow',
  'rurub',
  'snake',
  'konar',
  'piero',
  'gaeun',
  'enjum',
  'redja',
  'susuh',
  'panoe',
  'ngdul',
  'jogya',
  'kuper',
  'imana',
  'apoed',
  'sakie',
  'lanas',
  'salad',
  'jampi',
  'supra',
  'gopar',
  'hayuu',
  'nyuci',
  'kedar',
  'jeter',
  'rusdy',
  'krang',
  'legis',
  'kerut',
  'aside',
  'tangu',
  'waaah',
  'tembo',
  'dilan',
  'hulun',
  'cloth',
  'palai',
  'capna',
  'korda',
  'belot',
  'sihan',
  'heueh',
  'umuma',
  'malan',
  'bebeh',
  'solli',
  'pompa',
  'sadya',
  'sigar',
  'yogie',
  'bisoe',
  'syech',
  'syria',
  'selot',
  'wants',
  'bgdas',
  'encik',
  'guhan',
  'koema',
  'geuis',
  'aubna',
  'ahama',
  'galar',
  'kerid',
  'gerar',
  'dagon',
  'gaong',
  'papas',
  'takun',
  'tagtu',
  'ulami',
  'kuari',
  'tolok',
  'wukuf',
  'ranan',
  'tiran',
  'fiber',
  'mural',
  'jalok',
  'sapir',
  'halos',
  'nenny',
  'kurup',
  'enang',
  'least',
  'herit',
  'seura',
  'supis',
  'rapel',
  'cicau',
  'topoi',
  'galio',
  'ating',
  'gayah',
  'dufan',
  'harim',
  'matya',
  'ambun',
  'gelik',
  'ngaho',
  'heneg',
  'darci',
  'rutab',
  'dasti',
  'kokod',
  'doeka',
  'ayuna',
  'cigna',
  'negas',
  'kabet',
  'solah',
  'peels',
  'lunak',
  'becir',
  'patis',
  'regag',
  'sabau',
  'sarka',
  'ibuan',
  'tabur',
  'modeu',
  'beral',
  'taean',
  'lisna',
  'rehat',
  'ceput',
  'garaa',
  'eeeeh',
  'leuih',
  'ooooh',
  'sedil',
  'moyeg',
  'heded',
  'iting',
  'theme',
  'weusi',
  'nengo',
  'unwat',
  'tubah',
  'jigme',
  'jeluk',
  'riduh',
  'innaa',
  'yasau',
  'haneu',
  'nande',
  'pogat',
  'damgl',
  'soren',
  'mnang',
  'choti',
  'gadeg',
  'sagep',
  'parak',
  'sarol',
  'kasun',
  'indik',
  'duwur',
  'robam',
  'ayeum',
  'akmad',
  'didos',
  'kepri',
  'beyak',
  'rawis',
  'genei',
  'whole',
  'kokin',
  'sinih',
  'slalu',
  'dmana',
  'agiep',
  'andar',
  'geleh',
  'rurut',
  'metin',
  'euhhh',
  'belat',
  'emabn',
  'danas',
  'naeka',
  'kunsi',
  'helor',
  'nukri',
  'tikus',
  'sacer',
  'youth',
  'babag',
  'razak',
  'butha',
  'bahja',
  'habut',
  'riweh',
  'ngaya',
  'tabor',
  'ipung',
  'bande',
  'ogona',
  'senah',
  'hiyuk',
  'landy',
  'purak',
  'truck',
  'saram',
  'maing',
  'huang',
  'tabet',
  'windy',
  'tanen',
  'ciaul',
  'ginih',
  'rupee',
  'trasi',
  'bakom',
  'surik',
  'ngler',
  'totog',
  'ajrek',
  'jeleg',
  'imani',
  'robun',
  'marab',
  'diuja',
  'euren',
  'angut',
  'karot',
  'dasta',
  'risuh',
  'kerar',
  'leher',
  'kulku',
  's$rat',
  'moeaa',
  'negek',
  'malki',
  'tuong',
  'deman',
  'swios',
  'retem',
  'lugat',
  'ruteu',
  'yumna',
  'upamu',
  'payad',
  'marwa',
  'derrr',
  'behjo',
  'dayan',
  'ngabu',
  'tagoh',
  'tigar',
  'menko',
  'desti',
  'doepa',
  'surot',
  'giyim',
  'sebah',
  'kawak',
  'noken',
  'sudna',
  'louis',
  'beruk',
  'jagur',
  'kreyo',
  'cekep',
  'pepep',
  'berab',
  'esrom',
  'demor',
  'tatto',
  'bagya',
  'laser',
  'seyot',
  'unjam',
  'ngamu',
  'cukil',
  'gedur',
  'amrit',
  'jedor',
  'weduh',
  'menje',
  'kadot',
  'wusul',
  'leboe',
  'kawar',
  'gusit',
  'zahir',
  'senuk',
  'anuak',
  'tuwew',
  'maker',
  'dhede',
  'sesel',
  'swear',
  'putul',
  'gesut',
  'mugut',
  'kawik',
  'ageun',
  'masem',
  'sabna',
  'yaiku',
  'wadag',
  'lubta',
  'saroe',
  'maadi',
  'lucuh',
  'konan',
  'ruwas',
  'dohoy',
  'desdm',
  'pamuh',
  'rabuh',
  'kumat',
  'jutuh',
  'ikang',
  'balab',
  'kodel',
  'lulah',
  'santo',
  'ruhai',
  'tayib',
  'dansa',
  'ethom',
  'tarip',
  'gibug',
  'ucrit',
  'ledot',
  'arsim',
  'koreh',
  'engki',
  'guguh',
  'jebug',
  'tadih',
  'boast',
  'ngaga',
  'umbak',
  'perdu',
  'rohmi',
  'essai',
  'kalar',
  'bruce',
  'bayuh',
  'stupa',
  'norog',
  'getem',
  'biran',
  'masur',
  'medah',
  'ridna',
  'tiyap',
  'petra',
  'silig',
  'mawan',
  'tarto',
  'poles',
  'pukis',
  'labut',
  'koena',
  'driya',
  'gorda',
  'wujus',
  'libni',
  'birus',
  'smana',
  'gngke',
  'nekat',
  'tajam',
  'waiya',
  'cihoe',
  'diaud',
  'joban',
  'beyar',
  'peren',
  'berot',
  'riyeg',
  'minge',
  'modis',
  'astro',
  'enugn',
  'tekuk',
  'redih',
  'anion',
  'talta',
  'resin',
  'morok',
  'mudut',
  'guyah',
  'betal',
  'dolog',
  'vibra',
  'agoel',
  'mesam',
  'kajah',
  'dacia',
  'lekob',
  'katja',
  'lamat',
  'kulis',
  'pered',
  'cyber',
  'jelem',
  'fahri',
  'jawar',
  'hinom',
  'rudya',
  'adoeh',
  'sopal',
  'warsi',
  'kakoe',
  'hegae',
  'supit',
  'matuk',
  'nahur',
  'loyor',
  'purka',
  'batna',
  'beris',
  'wadis',
  'jisin',
  'bsdas',
  'parut',
  'aweuh',
  'ngana',
  'newok',
  'nayna',
  'munuu',
  'antam',
  'bopih',
  'dekna',
  'tring',
  'miroj',
  'eupil',
  'cerai',
  'bunut',
  'pukuh',
  'bress',
  'oprek',
  'padog',
  'madog',
  'selap',
  'tepar',
  'comhu',
  'bacem',
  'akeul',
  'lamot',
  'dawet',
]
