// Sumber:
// https://www.kamussunda.net/

export const DEFINITIONS: { [key: string]: string } = {
  antep: 'biarkan',
  jalma: 'manusia, orang',
  dawuk: 'abu-abu (warna bulu kuda)',
  hawuk: 'kelabu seperti abu',
  endah: 'indah, cantik',
  pisan: 'amat, sangat',
  sarua: 'sama, serupa saruana',
  uteuk: 'otak',
  eyang: 'nenek, kakek; bahasa halus dari nini atau aki',
  dalit: 'menyatu',
  getol: 'rajin',
  eurih: 'alang-alang, ilalang',
  kocor: 'mengalir, mengucur',
  halis: 'alis',
  tiasa: 'bisa, dapat; bahasa sedang dari bisa',
  cokot: 'mengambil',
  putra: 'anak; bahasa halus dari anak',
  ambek: 'marah',
  maneh: '1. kata ganti orang kedua; kamu, engkau; 2. diri, sendiri',
  nitah: 'menyuruh',
  jajap: 'mengantar; bahasa halus untuk nganteur',
  antay: 'berbanjar panjang, antri',
  lamun: 'kalau, jika',
  apung: 'terbang',
  harti: 'arti',
  pinuh: 'penuh',
  kawit: 'asal, mula; mula-mula bahasa halus dari asal atau mimiti',
  sejen: 'lain; bukan yang ini atau bukan yang itu',
  mengi: 'asma',
  uplek: 'asyik, tampak menyenangkan (yang sedang bercakap-cakap)',
  atawa: 'atau',
  hayam: 'ayam',
  soang: 'angsa',
  lemar: 'makan sirih; bahasa halus dari nyeupah',
  kadek: 'membacok',
  bagea:
    'kata seru untuk menyatakan rasa rindu kepada yang datang dan jarang bertemu',
  pedar: 'menerangkan dengan panjang lebar, menguraikan',
  mulih: 'pulang; bahasa halus dari balik',
  balik: 'pulang',
  balur: 'obat yang dioleskan ke tubuh',
  liwar: 'bandel, tidak menuruti perintah atau nasihat',
  gugah: 'bangun; bahasa halus dari hudang',
  kulem: 'tidur; bahasa halus dari sare',
  sugan: 'barangkali, kalau-kalau, siapa tahu',
  kulon: 'barat',
  anyar: 'baru',
  karek: '1. barusan, baru saja; 2. baru terjadi, barulah',
  wasuh: 'mencuci kaki, dsb, membasuh',
  semet: '1. mulai; 2. hingga, sampai',
  ambeu: 'bau',
  mayar: 'membayar',
  beber: 'mengembangkan (sayap, layar)',
  entog: 'entok; itik manila; itik surati',
  becek: 'becek',
  geseh: 'beda atau berubah',
  pupur: 'bedak',
  wedak: 'bedak',
  bedug: 'beduk',
  tabuh: 'pukul, jam; bahasa halus dari pukul',
  bejad: '1. pecah; retak; 2. bejat',
  bekel: 'bekal',
  tilas:
    '1. bekas, mantan; bahasa halus dari urut; 2. menetak (misalnya dengan golok) sampai putus',
  palih:
    '1. paruh, bagian; bahasa halus dari paro atau wareh; 2. sebelah; bahasa halus dari beu-lah; palih kiwa, sebelah kiri',
  engke: 'berjalan dengan sebelah kaki, nanti, entar',
  meser: 'membeli; bahasa sedang dari meuli',
  meuli: 'membeli',
  tacan: 'belum',
  leres: 'usai, selesai',
  bener: '1. benar, betul; 2. jujur, lurus; 3. cocok',
  binih: 'benih',
  jeung: '1. ditambah; 2. dan, dengan; 3. atau; 4. daripada',
  malik: 'membalikkan badan; berbalik',
  beuli:
    '1. segala hal yang harus dibeli; 2. ingin selalu dibujuk-bujuk; manja',
  wagel: 'melarang, menghalang-halangi; bahasa halus dari nyaram',
  carek: '1. ucapan, perkataan; 2. nyarek, melarang; 3. nyarekan, memarahi',
  pirak: 'bercerai; bahasa halus dari pepegatan',
  pegat: 'putus',
  bogoh: 'cinta',
  getih: 'darah',
  dehem: 'berdeham',
  dekem: 'mendekam',
  hieng: 'berdesing',
  deang: 'berdiang; menghangatkan tubuh dekat api',
  jajar: 'berbaris, berjajar-jajar pasar, sedang-sedang saja rupanya (wanita)',
  genep: 'enam',
  gelar: '1. lahir, muncul; 2. titel',
  noyek: 'berduyun-duyun atau bergerombol sehingga sulit untuk bergerak',
  puket: 'bergumul',
  hajat: '1. kenduri; 2. selamatan, sedekah',
  gelut: 'berkelahi',
  junun: 'bersungguh-sungguh dalam belajar atau bekerja',
  liren: 'berhenti; bahasa halus dari eureun',
  katut: 'dan, serta; bersama',
  mapah: 'berjalan kaki',
  beres: '1. beres, rapi; 2. selesai dengan baik, sukses',
  hibar:
    '1. baik-baik saja, sehat walafiat; bahasa halus dari berkah; 2. cahaya yang nampak di langit ketika ada kebakaran besar, matahari terbit, dsb',
  wargi: 'bahasa halus dari warga',
  liwat: 'lewat',
  letah: 'lidah',
  heula: '1. dahulu; 2. cepat, depan, muka',
  liang: 'lubang; liang irung, lubang hidung',
  ameng: '1. main, bermain-main; bahasa halus dari kata ulin; 2. bermain silat',
  palay:
    '1. mau; bahasa halus dari hayang; 2. capek; bahasa halus dari cape; 3. mengantuk; bahasa halus dari tunduh',
  lucir: 'licin berminyak',
  dumuk: 'menetap, tinggal',
  napsu: 'nafsu',
  pamit: 'minta diri; permisi akan pergi (berangkat, pulang)',
  tempo: 'waktu, tempo',
  pesen: 'pesan',
  mikir: 'berpikir',
  babar:
    '1. melahirkan; bahasa halus dari ngajuru; 2. banyak, merekah, mengembang; 3. meninggal dunia pada saat itu juga',
  pasea: 'bertengkar',
  dines: 'dinas',
  robah: 'ubah, berubah',
  buruy: 'berudu',
  bared: 'baret; luka tergores kayu, duri, karena jatuh, dsb',
  baeud: 'bermuka masam, cemberut',
  watek: 'watak, pembawaan, bakat',
  besan: 'ibu atau ayah menantu',
  badag: '1. besar; 2. tidak halus, besar-besar',
  beusi: 'besi',
  bitis: 'betis',
  dawam: 'biasa; terbiasa (karena dibiasakan)',
  biwir: 'bibir',
  wijen: 'bijan',
  damel: 'kerja; bahasa halus dari gawe',
  bilik: 'anyaman dari bambu untuk dinding rumah, dsb',
  bulao: 'biru langit',
  pireu: 'bisu',
  bendo: 'ketahuan kesalahannya',
  momot: 'bobot',
  budak: 'anak-anak',
  belet:
    '1. bodoh; 2. kabelet, kebelet, beletek;kata antar untuk barang tipis yang pecah atau retak, seperti gelas',
  waduk:
    '1. kotoran manusia atau binatang; bahasa kasar sekali dari tai; 2. waduk, bendungan',
  boros:
    '1. anak atau tunas tumbuh-tumbuhan yang berumpun, seperti lengkuas, kunyit, dsb; 2. boros, tidak irit; 3. bagur, cepat besar',
  hatur: 'beri; bahasa halus dari bere',
  jieun: 'membuat, membikin',
  bubar: '1. bubar; 2. selesai, usai',
  torek: 'tuli',
  bogel: 'hampir bugil, hanya mengenakan celana dalam saja',
  bobor: '1. berbuka puasa; bahasa halus dari buka puasa; 2. bobol, jebol',
  sanes: 'bukan; bahasa halus dari lain',
  pasir: 'bukit',
  sasih: 'bulan; bahasa halus dari bulan',
  patos: 'tidak seberapa; bahasa halus dari teu pati',
  buruh: '1. buruh; 2. upah',
  manuk: 'burung',
  butek: 'bodoh; atau tidak bisa berpikir karena sedang sakit atau bingung',
  puyar: 'buyar',
  rubuh: 'roboh',
  dulur: 'saudara',
  betot: 'menarik kuat-kuat',
  kuris: 'cacar',
  cacad: '1. cacat; 2. nyacad; menghina; mencela',
  cagak: 'cabang dua',
  cakar: '1. nyakar, mencakar; 2. tapak atau bekas kaki ayam, dsb',
  serat: '1. tulis; bahasa halus dari tulis; 2. surat; bahasa halus dari surat',
  cawet: 'cawat',
  catet: 'mencatat',
  isang: 'bercebok',
  caram: 'melarang',
  rikat: 'cekatan, tangkas',
  cekek: 'mencekik',
  dadak: 'tiba-tiba, sekonyong-konyong',
  batan: '1. daripada; 2. ketimbang',
  dedek: 'dedak; ampas',
  dekil: 'kotor (karena debu, oli, dsb)',
  caket: 'dekat; bahasa halus dari deukeut',
  dangu: 'mendengar; bahasa halus dari ngadenge',
  denge: 'mendengar, tidak tuli',
  julig: 'dengki',
  derek: 'saudara, famili, bahasa halus dari dulur',
  payun: 'depan; bahasa halus dari hareup',
  nyeri: '1. sakit, nyeri; 2. penyakit',
  sedek: 'bunga salak',
  wawuh: 'kenal',
  aping: 'menyertai, menjaga, membimbing, mendamping',
  dagor:
    'membenturkan kepala (orang lain), pada benda keras yang kokoh, seperti tiang, tembok, dsb',
  pecut: 'cambuk',
  cabut: 'mencabut',
  teang: '1. menengok, melayat; 2. memanggil (menyuruh datang)',
  cicil: 'mencicil, mengangsur',
  desek: 'mendesak',
  poyok: 'mengejek, mencemoohkan, menjelek-jelekkan',
  gebug: '1. ngagebug; pukul',
  gegel: 'menggigit',
  gilir: 'bergeser, pindah tempat',
  godog: 'menggodok',
  palid: '1. mengalir ke hulu; 2. hanyut',
  hirup: 'hidup',
  palar: 'harap',
  etang: 'hitung; bahasa halus dari itung',
  itung: '1. berhitung; 2. menghitung; 3. memakai perhitungan',
  inget: '1. ingat; 2. awas, hati-hati',
  incer: '1. mengincar, 2. membidik',
  lebet: 'masuk, dalam; bahasa halus dari asup atau jero',
  kaput: 'menjahit',
  letak: 'menjilat',
  sebut: 'menyebutkan',
  omong: 'ucapan, bahasa, atau perkataan',
  nyaho: 'tahu',
  pesek: '1. mengupas; 2. arti kiasan membahas, menerangkan',
  bedah: '1. jebol, bobol; 2. putus; 3. bedah, operasi',
  tuluy: 'terus, lalu, selanjutnya',
  tulus: 'jadi dilaksanakan',
  alung: 'melemparkan',
  ciduh: 'ludah',
  wayuh: 'mempunyai istri lebih dari satu; bahasa halus dari nyandung',
  tuang: 'makan; bahasa halus untuk makan; kata ganti untuk orang kedua',
  dahar: 'makan',
  antos: 'tunggu; bahasa halus untuk kata dago',
  tiris: 'dingin (udara, badan, dsb)',
  pacul: 'cangkul',
  pager: 'pagar',
  pasti: 'pasti, tentu',
  cekel: 'mencekal; memegang',
  tampa: 'menerima',
  takol: 'memukul menggunakan palu, dsb',
  gaduh: 'punya; bahasa sedang dari boga',
  puter: 'berputar',
  cabak: '1. sentuh, pegang; 2. kerja, tingkah, kelakuan',
  rebut: 'merebut',
  gecek:
    '1. diremas-remas (cucian, dsb.) supaya bersih; 2. diremas-remas daun digesek daun telinganya; 3. arti kiasan dicecar dengan pertanyaan-pertanyaan yang sulit (yang sedang diuji)',
  cutat: 'mengutip',
  sered: 'menggusur, menyeret',
  tunda: 'menangguhkan, menunda',
  jabel: 'merampas, merebut',
  titah: 'menyuruh',
  taluk: 'takluk',
  sieun: 'takut',
  taros: 'tanya; bahasa halus dari tanya',
  tarik: '1. narik menarik; 2. keras (suara); 3. kencang (melaju)',
  tebak: 'meniup kencang (angin)',
  dedet:
    'memaksa memasukkan benda pada yang sudah penuh atau pada benda lain yang keras',
  teleg: 'telan',
  susud: 'mengusut atau mencari jejak (penjahat, binatang buruan)',
  batur: '1. teman, kawan yang bersama-sama dengan kita; 2. orang lain',
  lelep: 'karam, tenggelam',
  turut: 'menurut',
  bujur: 'pantat',
  cucuk: 'duri',
  encer: 'cair',
  gajih: '1. gaji; 2. lemak',
  kasep: 'tampan',
  galak: '1. galak; 2. buas',
  gurat: 'garis',
  ateul: 'gatal',
  gayot: 'menggantung serta tampak terkulai',
  gaber: 'gelambir, meng-gelambir',
  getek: '1. rakit; 2. geli',
  guyur: 'heboh, gempar, ramai',
  resep: '1. senang, suka; 2. cinta, tertarik',
  geret: 'garis ciri atau tanda batas',
  kesit: 'gesit',
  geter: 'bergetar',
  huntu: 'gigi',
  bedog: 'golok',
  gogog: 'menggonggong (anjing)',
  turih: 'toreh',
  pelem: 'gurih, lezat',
  lemes: '1. halus; 2. lembut',
  bobot: '1. beratnya; 2. mengandung; bahasa halus dari reuneuh',
  ancur: 'hancur, hancur lebur, rusak, porak-poranda',
  anduk: 'handuk',
  pupus:
    '1. mati, meninggal dunia; bahasa halus dari maot; 2. mupus, menghapus',
  maung: 'harimau',
  kedah: 'sedapat mungkin',
  manah: 'pikir atau hati; bahasa halus dari pikir atau hate',
  helok: 'heran',
  irung: 'hidung',
  hurup: 'huruf',
  jurig: 'hantu, momok',
  umbel: 'ingus; bahasa halus dari leho',
  babon:
    '1. bibit; 2. ikatan padi yang disediakan untuk upah menuai padi; 3. sumber dasar',
  jalir: 'mengingkari, ingkar',
  eling: '1. ingat; 2. siuman (dari pingsan, dsb)',
  noong: 'mengintai, mengintip lewat lubang kecil',
  iring: 'ikut, turut; bahasa halus dari ilu, pilu',
  istri: 'istri atau perempuan; bahasa halus dari pamajikan atau awewe',
  garwa: 'istri; bahasa halus dari pamajikan',
  jadah: 'anak haram; anak di luar nikah',
  jadug: 'jagoan, pentolan, pemimpin',
  jahat: '1. jahat; 2. merusak, merugikan; 3. boros, cepat habis',
  jajan:
    'membeli makanan (kue, dsb) yang dimakan waktu itu juga di luar rumah; jajan',
  randa: 'janda',
  labuh: 'jatuh',
  tebih: 'jauh; bahasa halus dari jauh',
  walon: 'menjawab, membalas; bahasa sedang dari ngajawab',
  cawel: 'menjawil, men-colek',
  tapak: 'bekas tangan, kaki, dsb',
  jejel: 'diisi sampai padat',
  papag: 'menjemput',
  bosen: 'bosan',
  layon: 'mayat; bahasa halus dari mayit',
  mayit: 'mayat',
  layad: 'menjenguk (orang sakit, dsb.); bahasa halus dari ngalongok',
  jawer: 'jengger (ayam)',
  macem: 'macam',
  jiret: 'jerat',
  jerit: 'menjerit',
  kapok: 'jera',
  jiwir: 'menjewer',
  jorok: 'tidak tahu atau tidak mengindahkan kebersihan',
  keder: 'bingung tidak tahu arah mata angin karena tersesat, dsb',
  kabur: '1. kabur; 2. lari cepat tak tertahankan (kuda, dsb.)',
  lawon: 'kain, tekstil',
  useup: 'kail, pancing',
  akang:
    'panggilan untuk saudara laki-laki yang lebih tua, suami, atau laki-laki lain yang lebih tua usianya',
  kawon: 'kalah; bahasa halus dari eleh',
  bilih: 'bahasa halus dari bisi',
  kemis: 'Kamis (nama hari)',
  dusun: 'kampungan',
  margi: 'sebab, karena; bahasa halus dari sabab atau marga',
  sabab: 'sebab, karena',
  dugal: 'kasar atau keras kepala',
  kasar:
    '1. kurang bagus buatannya; 2. hanya memerlukan tenaga jasmani; 3. tidak halus, yang terlihat',
  watir: 'kasihan',
  kecap: 'kata',
  cenah: 'katanya, ujarnya',
  pogot:
    'saking senangnya pada sesuatu, sehingga melupakan yang lainnya, keasyikan',
  kebon: 'kebun',
  berik: 'kejar',
  keker: 'kekar',
  keked: 'kaku jari-jemari tangan hingga tidak dapat memegang apa-apa',
  hilap: 'lupa; bahasa sedang dari poho',
  lalay: 'kelelawar',
  ubeng: 'mengelilingi',
  bijil: 'keluar',
  lugay:
    '1. keluar atau berhenti; bahasa halus dari kaluar atau eureun; 2 terbuka, seperti ular yang bergulung lalu melata',
  ledeh: 'sudah hampir hilang warna aslinya',
  keuna: 'kena',
  cikur: 'kencur',
  bobos: 'kentut; bahasa halus dari hitut',
  hitut: 'kentut',
  wareg: 'kenyang; bahasa halus dari seubeuh',
  sesek:
    '1. penuh sesak; 2. sesak nafas, 3. kenyang; bahasa halus dari seubeuh',
  sirah: 'kepala',
  andel: 'percaya',
  bodas: 'putih',
  angir: 'keramas, mencuci rambut',
  betah: 'digerebek; dihardik (orang yang sedang melakukan kejahatan)',
  teuas: 'keras',
  kerod: 'keriput (umpamanya bekas luka)',
  kiruh: 'keruh',
  tiung: 'kerudung, jilbab',
  keduk: 'mengeduk',
  nyaah: 'sayang',
  kelek: 'ketiak',
  sabot: 'sewaktu, tatkala',
  kolot: '1. tua; 2. orang tua; 3. asli; 4. kuno, ketinggalan jaman',
  kupat: 'ketupat',
  kenca: 'menarik tali di tempat yang akan digali, ditembok, dsb. agar lurus',
  urang: 'orang; aku; kita; mengajak; mari',
  kobok:
    '1. memasukkan tangan ke dalam wadah berisi air atau barang cair lainnya; 2. arti kiasan kepanjangan (lengan baju) sehingga menutupi tangan',
  kompa: 'pompa',
  kureb: 'mengubur mayat, mengebumikan; bahasa halus dari ngubur mayit',
  kemot: 'dijilati (bibir sendiri)',
  jaram: 'kuman',
  riung: 'berkumpul (mengelilingi makanan, dsb)',
  capek: 'mengunyah',
  butik: 'melepaskan tempurung dari butiran kelapa',
  ceuli: 'telinga',
  kuras: 'menguras',
  korsi: 'kursi',
  salin: 'menerjemahkan cerita atau menuliskan kembali seutuhnya',
  busik: 'kusut',
  jegud: 'kaya',
  rewog: 'gembul, makan banyak dan cepat',
  tenjo: 'melihat',
  lakon: '1. cerita sandiwara, film, dsb.; 2. perjalanan',
  lalab: 'lalap',
  lante: 'lantai',
  teras: 'terus; bahasa halus dari terus atau tuluy',
  baris:
    '1. baris-berbaris; 2. baris; deretan huruf pada tulisan atau cetakan; 3. akan',
  sangu: 'nasi',
  rubak: 'lebar; jalan rubak, jalan lebar',
  layan: 'sebanding',
  laden: 'melayani, memenuhi permintaan orang lain (langganan, majikan, dsb)',
  lebar: 'sayang',
  gasik: 'cepat, lekas',
  legok: 'berlubang (tanah, dsb.)',
  licik: 'licik, curang',
  nyere: 'lidi',
  logor: 'longgar, mudah masuk',
  leuwi: 'lubuk, kedung',
  lukut: 'lumut',
  mabok: 'mabuk',
  beuki: '1. senang memakan atau melakukan; suka; 2. bertambah, makin',
  malih: 'bahkan; bahasa halus dari malah',
  malah: 'bahkan',
  wengi: 'malam; bahasa halus dari peuting',
  kedul: 'malas',
  males: 'membalas',
  gabug: '1. hampa tidak berisi (padi); 2. mandul',
  bendu: 'marah; bahasa halus dari ambek',
  panon: 'mata',
  nilar: 'meninggalkan',
  mekar: '1. mekar; 2. arti kiasan berkembang',
  megar: '1. menetas; 2. berganti kulit (ular); 3. pecah-pecah. (kulit orang)',
  madon: 'melacur, bermain perempuan',
  neang: '1. menengok, melayat; 2. memanggil (menyuruh datang)',
  leyur: 'hancur menyatu dengan air, galau',
  matak: 'bisa menjadi sebab; sebab-musabab',
  giwar: 'melenceng',
  lesot: 'lepas (tali pengikatnya atau dari pegangan)',
  lipur: 'pulih dari kesedihan atau kesusahan; lipur',
  ancul: 'melonjak, melambung, memantul ke atas',
  maksa: 'memaksa',
  emang: 'panggilan kepada paman atau laki-laki yang sebaya dengan ayah',
  macok:
    '1. mematuk (ayam, burung); 2. menggigit (ular); dipacok oray, digigit ular',
  pacok:
    '1. mematuk (ayam, burung); 2. menggigit (ular); dipacok oray, digigit ular',
  duruk: 'membakar',
  ruhay: 'membara',
  wates: 'batas',
  belek: 'membedah; menetas; membelah',
  bekem:
    '1. membekap; menyumbat mulut dengan tangan secara paksa; 2. membungkam; melarang mengeluarkan pendapat dengan bebas',
  milih: 'memilih',
  menta: 'meminta',
  mulas: '1. mewarnai; 2. memoles',
  muter: 'berputar',
  naker: 'sangat, sekali; alus naker',
  melak: 'menanam',
  nawis: 'menandatangani; bahasa halus dari neken atau nandatangan',
  ceker: 'kuku binatang berkaki empat yang terbelah dua',
  macul: 'mencangkul',
  nyium: 'mencium',
  golak: 'mendidih',
  nebak: 'meniup kencang (angin)',
  neleg: 'menelan',
  cabok: 'menempeleng, menabok',
  nolak: 'menolak, menampik',
  terap: 'menempel, ada hasilnya',
  nerap: 'menempel, ada hasilnya',
  medar: 'menerangkan dengan panjang lebar, menguraikan',
  ulang: 'mengayunkan; ngulangkeun pacul, mengayunkan cangkul',
  leber: 'luber, terlalu penuh, sehingga hampir meluap atau tumpah',
  gupay: 'melambai-lambaikan tangan',
  kerek: 'mendengkur',
  ngais: 'menggendong dari samping; mengambin',
  magak: '1. melarang, menghalangi; 2. mencela, menegur',
  ampar: 'menghampar',
  talar: 'mencongak, pidato ceramah, dsb. tanpa teks',
  nalar: 'mencongak, pidato ceramah, dsb. tanpa teks',
  ngece: 'melecehkan, menghina',
  heuay: 'menguap',
  angob: 'menguap; bahasa halus dari kata heuay',
  ruang: 'mengubur diruang kerepes',
  mesek: '1. mengupas; 2. arti kiasan membahas, menerangkan',
  nikah: 'menikah; bahasa halus dari kawin',
  lisah: 'minyak; bahasa halus dari minyak',
  niron: 'meniru, mencontoh, menyontek',
  mikat: 'membujuk-bujuk disertai maksud-maksud tertentu',
  dedel: '1. menjejali; 2. menginjak tanah dengan keras',
  mapag: 'menjemput',
  nulis: 'menulis',
  mudun: 'menurun (jalan)',
  nurut: 'menurut',
  nojos: 'menusuk, mencucuk, toker',
  murub: 'menyala',
  nyana: '1. tahu atau kalau tahu; 2. menyangka, mengira',
  ngait: 'tersangkut',
  seger: 'segar',
  nunda: 'menangguhkan, menunda',
  asong: 'disodor-sodorkan',
  cocok: 'penyumbat; benda untuk menutupi lubang',
  yuswa: 'umur; bahasa halus dari umur',
  pugur: '1. meranggas, berguguran; 2. arti kiasan habis keturunannya',
  pedes: 'lada',
  wadah: 'tempat untuk menyimpan sesuatu',
  gigir: 'di samping',
  penta: 'meminta',
  upami: 'bahasa halus dari upama',
  ngora: 'muda',
  ampuh: '1. manjur, mujarab; 2. lemah gemulai, lembut (tingkah laku)',
  muluk:
    'terbang amat tinggi; cita-citana muluk, cita-citanya yang amat tinggi, belum tentu tercapai',
  baham: 'mulut; bahasa halus dari sungut',
  wedal: 'keluar, terbit, lahir',
  mirah: 'murah; bahasa halus dari murah',
  nadar: 'nazar',
  wasta: 'nama; bahasa sedang dari ngaran',
  ngaco:
    '1. berkata-kata tidak karuan; mengigau; 2. tidak benar jalannya (jam tangan, dsb)',
  kebut: 'bersihkan',
  nyiru: 'niru',
  eneng:
    '1. anak kerbau; 2. ngeneng-ngeneng; 3. panggilan untuk anak perempuan golongan bangsawan menengah (dahulu)',
  nagog: 'berjongkok',
  pokna: 'katanya, ujarnya',
  indit: 'pergi, berangkat',
  pacet: '1. lintah yang hidup di darat; 2. macet-macet',
  padet: 'penuh sesak; padat',
  pajeg: 'pajak',
  anggo: 'pakai; bahasa halus untuk kata pake',
  parab: 'makanan ternak, pakan',
  tuduh: 'tunjuk',
  parna: 'parah; gering pama, sakit parah; tatu parna, cedera berat',
  parud: 'parut',
  bayah: 'paru-paru',
  pites: 'patah',
  patok: 'tonggak, pancan',
  tumut: 'turut; bahasa halus dari turut',
  rampe: 'rampai',
  pegel: 'pegal',
  puade: 'pelaminan',
  damar: 'lampu, pelita',
  pelor: 'peluru',
  penca: 'pencak (silat)',
  tilik: 'memandang, melihat',
  nilik: 'memandang, melihat',
  mabek: 'pengap',
  jidar: 'mistar, penggaris',
  ceret: 'memercik',
  paria: 'pare',
  jempe: 'diam, tidak terdengar lagi bersuara',
  demes: 'pesek',
  jampe: 'jampi',
  gelap: 'petir, geledek',
  pijer: 'pijar',
  imbit: 'pinggul; bahasa halus dari birit',
  untun: 'dikepang',
  panto: 'pintu',
  damis: 'pipi; bahasa halus untuk kata pipi',
  pulas: '1. warna, rupa; 2. pewarna',
  perih: 'prihatin',
  beger:
    '1. mulai timbul rasa berahi, akil balig; 2. burung yang tidak berhenti berkicau',
  peang: 'pudar',
  pucet: 'pucat',
  pules: 'pulas',
  bujal: 'pusar',
  lieur: 'pusing kepala',
  rieut: 'sakit kepala',
  lamur: 'rabun',
  wanda: 'roman muka dan bentuk tubuh',
  begal: 'rampok',
  rante: 'rantai',
  ripuh: '1. sibuk, repot; 2. parah',
  ranca: 'rawa',
  werit: 'rawan',
  rebab: 'alat musik gesek bertali dua atau tiga',
  kulub: 'merebus',
  remeh: 'remah',
  rames: 'meremas-remas',
  rangu: 'renyah',
  rikes: 'ringsek',
  murag: 'jatuh',
  rugel: 'rugi, bahasa halus dari rugi',
  jukut: 'rumput',
  pande: 'rupa atau raut muka',
  sobat: 'sahabat, teman karib',
  pesak: 'saku',
  lepat: 'salah; bahasa halus dari salah',
  kawas: 'cara, seperti',
  dumeh: 'lantaran, oleh karena',
  sebel: '1. enek, merasa mual; 2. sebal',
  sebat: 'sebut; bahasa halus dari sebut',
  sedep: 'kesukaan',
  sadia: '1. sedia; 2. siap, sanggup',
  cawis: 'sedia',
  sedot: '1. menyedot; 2. mengalir deras',
  kabeh: 'semua',
  babad:
    '1. dongeng yang mengandung unsur sejarah, umpamanya Babad Cikundul, Babad Galuh, Babad Godog; 2. sesama, sebanding; 3. membabat rumput (tanaman)',
  aling: 'sekat, penghalang',
  kidul: 'selatan',
  hinis: 'sembilu',
  seseg: 'berisi (badan); tampak kuat dan kokoh (barang)',
  ampeg:
    '1. berat berisi; 2. sesak napas, asma; bahasa halus dari mengi atau bengek',
  rupek: 'sempit (rumah, dsb)',
  seeng: 'dandang',
  todel: 'menyinggung sedikit (dengan tangan)',
  kesed: 'sepet',
  sipuh: 'menyepuh',
  keser: 'menggeser, bergeser',
  peura: 'serak',
  serep:
    '1. menyerap; 2. arti kiasan; dapat dimengerti serta terasa kebenarannya',
  ajrih: 'segan, malu',
  ambeh: 'supaya, agar',
  ancik: 'diam menetap; tinggal',
  angar: 'tanah gersang, tandus',
  anjog: 'tiba, sampai',
  awewe: 'wanita, perempuan',
  bagja: 'bahagia',
  bakal: '1. bahan; 2. akan',
  balem: '1. merapatkan bibir, 2. bungkam; diam seribu bahasa',
  batok: 'tempurung',
  beuti: 'umbi',
  bobok: 'melubangi tembok, cadas, bambu, dsb',
  caang:
    '1. terang; 2. bersih dari pepohonan yang tadinya menghalangi sinar matahari',
  cacak: 'walaupun',
  cedit: 'kikir',
  cikal: 'sulung',
  cocog: '1. setuju; 2. cocok',
  cokel: 'mencukil, mencungkil; mencongkel',
  colok: 'mencolok; mencocok',
  curuk: 'telunjuk',
  demit: 'tersembunyi rapat-rapat sehingga sulit diketahui',
  dinya:
    '1. kata ganti untuk menunjukkan tempat yang tidak terlalu jauh; sana; 2. kata ganti orang kedua; kamu, engkau',
  endog: 'telur',
  eupan: 'umpan',
  garah: 'rusak, tak bisa dipakai lagi',
  gawir: 'tebing',
  gedeg: 'gedek',
  hiber: 'terbang',
  iteuk: 'tongkat',
  jaman: 'zaman',
  jeger: 'kaku',
  jejer: 'berjajar',
  kagok: 'canggung, tidak bebas',
  kahot: 'tua, kuno, tapi berharga; antik',
  kaler: 'utara',
  kebek: 'penuh',
  kemba: 'pemalu, tidak berani mengatakan hal yang seharusnya dikatakan',
  kotok: 'ayam',
  lipet: 'lipat',
  luhur: 'tinggi',
  maher: 'mahir',
  mandi: 'mandi',
  mebes: 'terperosok ke dalam lumpur atau rawa',
  meded: 'perlente',
  meres: 'memeras',
  nakol: 'memukul menggunakan palu, dsb',
  nampa: 'menerima',
  nempo:
    '1. melihat dari tempat yang dibatasi, dari dalam ke luar atau sebaliknya; 2. menjenguk, melayat, menengok',
  paila: 'paceklik',
  parat: 'tembus',
  patri: 'solder',
  pawon: 'dapur; bahasa halus dari dapur',
  pelak: 'menanam',
  petet: 'mata kecil seperti yang setengah tertutup',
  punah: 'lunas',
  ramat: 'serat',
  rapet: 'melekat, merekat, rapat',
  rapih: '1. damai, tidak bermusuhan; 2. rapi',
  regep: 'mendengarkan baik-baik, menyimak',
  regot: 'kata antar untuk minum',
  saban: 'setiap; saban taun, setiap tahun',
  salse: '1. santai, tidak terburu waktu; 2. selesai',
  samak: 'tikar',
  sapuk: 'setuju, sepakat, akur',
  sarta: 'serta',
  sarwa: 'serba',
  satia: 'setia',
  semah: 'tamu',
  seuri: 'tertawa',
  sirit: 'kemaluan laki-laki',
  sisit: 'sisik',
  surup: '1. terbenam (matahari, bulan);',
  taker: 'takar',
  takis: 'tangkis',
  tande: 'menampung, menadah (air hujan, dsb.)',
  tapis: 'pandai, pinter dalam suatu hal',
  tegep: 'tegap, ganteng, bagus',
  teger:
    'pancing yang bertali pendek, jorannya ditancapkan, di sungai, dsb. biasanya dari sore hingga pagi',
  teges: 'jelas',
  teguh: 'menerka, menebak',
  tepas: 'beranda',
  tetep: 'tetap',
  tinun: 'tenun',
  tiwas: 'celaka',
  tumbu: 'sambungan (benang, kawat, tali, dsb.)',
  turuy: 'tandan',
  ucing: 'kucing',
  waras: 'sehat',
  warta: 'kabar, berita',
  wayah: 'waktu',
  weruh: 'tahu',
  wetan: 'timur',
  wiati: 'angkasa',
  wungu: 'ungu',
  balad: '1. pasukan tentara; 2. teman seia sekata',
  pedah: 'disebabkan, oleh karena',
  ibing: 'tari',
}
